import CustomButton from '../../../Components/Common/CustomButton';
import { ButtonColor } from '../../../Utils/constants';

type Props = {
  isBackButton: boolean;
  isNextButton: boolean;
  isDivider?: boolean;
  isSubmitButton?: boolean;
  isCreateAccountButton?: boolean;
  isNextButtonDisabled?: boolean;
  errorMessage?: string;
  errorDescription?: string;
  backFunction?: () => void;
  nextFunction?: ((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | (() => void);
  createAccountFunction?:
    | ((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | (() => void);
};

const ButtonsSection = ({
  isDivider = true,
  isBackButton,
  isNextButton,
  isNextButtonDisabled,
  isSubmitButton,
  isCreateAccountButton,
  errorMessage,
  errorDescription = '',
  backFunction,
  nextFunction,
  createAccountFunction,
}: Props) => {
  return (
    <div>
      {isDivider && <div className="w-full h-[1px] bg-gray-17 mt-8"></div>}
      <div className={'justify-end flex flex-col md:flex-row gap-4 md:gap-0 pt-4'}>
        <div className="w-full flex flex-row items-end justify-between">
          <div className="flex justify-between w-full">
            {isBackButton && (
              <div
                className={
                  isNextButton || isCreateAccountButton
                    ? 'w-1/2 flex justify-start items-center'
                    : 'w-full flex justify-end items-center'
                }
              >
                <CustomButton onClick={backFunction} colorClass={ButtonColor.White}>
                  Back
                </CustomButton>
              </div>
            )}

            {isNextButton && (
              <div
                className={
                  isBackButton
                    ? 'w-1/2 flex justify-end items-center'
                    : 'w-full flex justify-end items-center'
                }
              >
                {errorMessage && (
                  <div className="flex flex-col hidden md:block max-w-[500px] mr-4">
                    <p className="text-sm text-red-0 text-start">{errorMessage}</p>
                    <p className="text-xs text-start">{errorDescription}</p>
                  </div>
                )}
                <CustomButton
                  onClick={nextFunction}
                  type={isSubmitButton ? 'submit' : 'button'}
                  disabled={isNextButtonDisabled}
                  colorClass={
                    isNextButtonDisabled ? ButtonColor.DARK_BLUE_2 : ButtonColor.DARK_BLUE
                  }
                >
                  Next
                </CustomButton>
              </div>
            )}

            {isCreateAccountButton && (
              <div
                className={
                  isBackButton
                    ? 'w-1/2 flex justify-end items-center'
                    : 'w-full flex justify-end items-center'
                }
              >
                {errorMessage && (
                  <div className="flex flex-col hidden md:block max-w-[400px] mr-4">
                    <p className="text-sm text-red-0 text-start">{errorMessage}</p>
                    <p className="text-xs text-start">{errorDescription}</p>
                  </div>
                )}
                <CustomButton onClick={createAccountFunction} colorClass={ButtonColor.DARK_BLUE}>
                  Create Account
                </CustomButton>
              </div>
            )}
          </div>
        </div>
        {errorMessage && (
          <div className="flex flex-col md:hidden max-w-full block">
            <p className="text-sm text-red-0">{errorMessage}</p>
            <p className="text-xs">{errorDescription}</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default ButtonsSection;
