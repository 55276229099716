import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../Store';
import { changeBillingGroupAsync, updateBillingPermissionAsync } from '../../../Utils/api';
import {
  Admin,
  BUSINESS,
  INDIVIDUAL,
  Role,
  StatusReason,
  SubscriptionStatus,
} from '../../../Utils/constants';
import {
  formatDateString,
  makeFirstLetterCapital,
  validateEmail,
} from '../../../Utils/helperFunctions';
import BillingPermissionModal from '../Modals/BillingPermissionModal';
import BillingEmailModal from './BillingEmailModal';
import InfoAttribute from './InfoAtrribute';
import PackagesModal from './PackagesModal';

const InformationSummaryCard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const customer = useSelector((state: RootState) => state.signup.customer?.customer);
  const {
    status,
    package: supportPackage,
    activationDate,
    subscriptionDate,
    accountName,
    gcpSecurityGroup,
    gcpSubBillingAccountId,
    domain,
    accountType,
    pscPermissionGranted,
    user,
    users,
    statusDetails,
  } = customer;

  const role = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);
  const customerId = useSelector((state: RootState) => state.admin?.currentCustomer);

  const [isPackageModalOpen, setIsPackageModalOpen] = useState(false);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [billingGroup, setBillingGroup] = useState(gcpSecurityGroup ?? '');
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);

  const onSupportPackageUpdateClick = () => {
    setIsPackageModalOpen(true);
  };

  const onBillingPermissionUpdateClick = () => {
    setErrorMessage('');
    setIsBillingModalOpen(true);
  };

  useEffect(() => {
    setBillingGroup(gcpSecurityGroup ?? '');
  }, [gcpSecurityGroup]);

  const handleBillingGroup = () => {
    if (billingGroup && !errorMessage) {
      if (role === Role.ADMIN) {
        dispatch(
          changeBillingGroupAsync(
            billingGroup,
            setIsLoading,
            setIsBillingModalOpen,
            setErrorMessage,
            navigate,
            true,
            customerId,
          ),
        );
      } else {
        dispatch(
          changeBillingGroupAsync(
            billingGroup,
            setIsLoading,
            setIsBillingModalOpen,
            setErrorMessage,
            navigate,
            false,
          ),
        );
      }

      setBillingGroup('');
    } else {
      setErrorMessage('Please enter a valid input.');
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setBillingGroup(email);

    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
    } else {
      setErrorMessage('');
    }
  };

  const updatedBusinessName =
    customer?.businessName && customer.businessName.length > 60
      ? customer.businessName.slice(0, 60) + '...'
      : customer?.businessName ?? '';

  const updatedAccountName =
    accountName && accountName.length > 40 ? accountName.slice(0, 40) + '...' : accountName ?? '';

  const subscriptionDateParsed = subscriptionDate ? new Date(subscriptionDate) : null;
  const createdAtParsed = activationDate ? new Date(activationDate) : null;

  const dateToUse =
    subscriptionDateParsed && createdAtParsed && subscriptionDateParsed < createdAtParsed
      ? activationDate
      : subscriptionDate;

  const taglineText = subscriptionDate ? `Subscribed on ${formatDateString(dateToUse)}` : '';

  const BillingPermission = () => {
    return role === Role.ADMIN && !pscPermissionGranted ? (
      <div className="flex flex-col mt-2">
        <h4 className="text-start poppins-400">
          Billing permissions are not assigned.{' '}
          <button
            className="text-red-0 underline underline-offset-2"
            onClick={() => setIsPermissionModalOpen(true)}
          >
            Assign Now
          </button>
        </h4>
        <p className="text-xs poppins-400 text-gray-7">
          Before granting permissions, please verify customer details for eligibility.
        </p>
      </div>
    ) : null;
  };

  const handleBillingPermission = () => {
    if (customerId) {
      setIsPermissionModalOpen(false);
      dispatch(
        updateBillingPermissionAsync(customerId, true, setIsLoading, setErrorMessage, navigate),
      );
    }
  };

  // if role is Admin, fetch user name from users array
  // else fetch user name from logged in user object
  const customerName =
    role === Admin
      ? users && users.length
        ? `${makeFirstLetterCapital(users[0].firstName)} ${makeFirstLetterCapital(
            users[0].lastName,
          )}`
        : customer?.name
      : `${makeFirstLetterCapital(user.firstName)} ${makeFirstLetterCapital(user.lastName)}`;

  const suspendedReason = statusDetails ? statusDetails.reason : null;

  // Disable update support package if user "status" is 'active' yet 'pending-review'
  // or 'pending-cancellation' or 'cancelled'
  // or 'suspended' due to 'security', 'failed-payment' or 'pending-review' reasons
  const updateDisabled =
    (status === SubscriptionStatus.ACTIVE && suspendedReason === StatusReason.PENDING_REVIEW) ||
    status === SubscriptionStatus.PENDING_CANCELLATION ||
    status === SubscriptionStatus.CANCELLED ||
    (status === SubscriptionStatus.SUSPENDED &&
      (suspendedReason === StatusReason.SECURITY ||
        suspendedReason === StatusReason.FAILED_PAYMENT ||
        suspendedReason === StatusReason.PENDING_REVIEW));

  return (
    <div className="flex flex-col items-start py-[20px] px-[24px] bg-white-0 border rounded-[10px] border-gray-2 mb-6">
      <div className="flex flex-row w-full">
        <h1 className="poppins-700 text-3xl text-gray-1 whitespace-nowrap overflow-hidden text-ellipsis xxl:max-w-[58vw] max-w-[72vw]">
          {accountType === BUSINESS ? makeFirstLetterCapital(updatedBusinessName) : customerName}
        </h1>
      </div>

      <div className="flex flex-row gap-x-20 mt-[15px] w-full justify-between">
        {accountType === BUSINESS ? (
          <InfoAttribute title="Domain" value={domain ?? 'Not Available'} />
        ) : null}
        <InfoAttribute
          title="Status"
          value={status ? makeFirstLetterCapital(status) : ''}
          textColor={status === SubscriptionStatus.CANCELLED ? 'text-red-0' : ''}
        />
        <InfoAttribute title="Active Since" value={formatDateString(activationDate)} />
        <InfoAttribute
          title="Support Package"
          value={
            supportPackage ? supportPackage.charAt(0).toUpperCase() + supportPackage.slice(1) : ''
          }
          tagline={taglineText}
          update={updateDisabled ? undefined : onSupportPackageUpdateClick}
          buttonText="Change"
        />

        {isPackageModalOpen && (
          <PackagesModal
            supportPackage={supportPackage}
            setIsPackageModalOpen={setIsPackageModalOpen}
          />
        )}
      </div>
      <div className="flex flex-row gap-x-28  mt-[15px]">
        {accountType === BUSINESS && (
          <div>
            <InfoAttribute
              title="Billing Permissions"
              value={gcpSecurityGroup ?? ''}
              update={onBillingPermissionUpdateClick}
              buttonText="Update"
            />
            <BillingPermission />
          </div>
        )}

        {isBillingModalOpen && (
          <BillingEmailModal
            setIsBillingModalOpen={setIsBillingModalOpen}
            isLoading={isLoading}
            billingGroup={billingGroup}
            handleEmailChange={handleEmailChange}
            errorMessage={errorMessage}
            handleBillingGroup={handleBillingGroup}
          />
        )}
        <InfoAttribute
          title="Billing Subaccount ID"
          value={updatedAccountName}
          // Update it with backend value later
          subValue={
            gcpSubBillingAccountId
              ? gcpSubBillingAccountId.replace('billingAccounts/', '')
              : 'Not Available'
          }
          isCopy
        />
        {accountType === INDIVIDUAL && <BillingPermission />}
      </div>
      {isPermissionModalOpen && (
        <BillingPermissionModal
          setIsModalOpen={setIsPermissionModalOpen}
          handleYes={handleBillingPermission}
        />
      )}
    </div>
  );
};
export default InformationSummaryCard;
