import { BusinessSteps, ErrorMessages, IndividualSteps } from '../../Types';

// This file will be used for exporting the constants
export {};
export enum ButtonColor {
  DARK_BLUE = 'text-white-0 bg-blue-1 hover:opacity-80 border border-blue-1',
  DARK_BLUE_2 = 'text-white-0 bg-blue-1 border border-blue-1',
  White = 'text-blue-1 bg-white-0 hover:opacity-80 border border-blue-1',
  Red = 'text-red-0 bg-white-0 hover:opacity-80 border border-red-0',
  RED_2 = 'text-white-0 bg-red-0 hover:opacity-80',
}

export enum DashBoardTab {
  DASHBOARD,
  BILLING_HISTORY,
  MANAGE_USERS,
  MY_CARDS,
  SUBSCRIPTION,
  MANAGE_CUSTOMERS,
}

export enum Card {
  OTHER = 'other',
  BACKUP = 'backup',
  PRIMARY = 'primary',
}
export enum Navigation {
  DEFAULT = '/',
  SIGN_UP = '/signup',
  DASHBOARD = '/dashboard',
  BILLING_HISTORY = '/billingHistory',
  MANAGE_USERS = '/manageUsers',
  MY_CARDS = '/managePayment',
  SUBSCRIPTION = '/subscription',
  MANAGE_CUSTOMERS = '/manageCustomers',
}

export const BUSINESS_STEPS: BusinessSteps = [
  { 1: 'Business Information' },
  { 2: 'Support Package' },
  { 3: 'Upload Documents' },
  { 4: 'Permissions' },
  { 5: 'Payment Information' },
];

export const INDIVIDUAL_STEPS: IndividualSteps = [
  { 1: 'Contact Information' },
  { 2: 'Support Package' },
  { 3: 'Payment Information' },
];

export const BILLING_HISTORY_TABLE_HEADERS = [
  'Month',
  'Amount',
  'Document_No',
  'Status',
  'Paid_On',
];

export const MANAGE_CUSTOMER_TABLE_HEADERS = [
  'Name',
  'Domain',
  'Account_Type',
  'Created_At',
  'Status',
];

export const MANAGE_USERS_TABLE_HEADERS = ['Name', 'Email', 'Created_On', 'Status'];

export const RIGHT_ALIGN_HEADERS = ['Created_At', 'Paid_On', 'Amount', 'Month'];
export const LEFT_ALIGN_HEADERS = [
  'Amount',
  'Document_No',
  'Name',
  'Domain',
  'Account_Type',
  'Email',
];

export const ONBOARDING_STEPS = {
  INITIAL_DETAILS: 'Initial Details',
  BUSINESS_INFORMATION: 'Business Information',
  SUPPORT_PACKAGE: 'Support Package',
  UPLOAD_DOCUMENTS: 'Upload Documents',
  BILLING_EMAIL: 'Billing Email',
  PAYMENT_INFORMATION: 'Payment Information',
  CONTACT_INFORMATION: 'Contact Information',
  SUCCESS: 'Success',
  TERMS: 'Terms',
};

export enum TrackStep {
  GOOGLE = 'google-info',
  INFO = 'basic-info',
  PACKAGE = 'support-package',
  DOCUMENT = 'upload-documents',
  BILLING = 'billing-email',
  PAYMENT = 'payment-info',
  TERMS = 'terms-conditions',
  SUCCESS = 'success-creation',
  COMPLETED = 'onboarding-completed',
}

export const INDIVIDUAL = 'individual';

export const BUSINESS = 'business';

export const WHITE = '#FFFFFF';

export const GRAY = 'rgba(34, 34, 34, 0.7)';

export const BLUE = '#4285F4';

export const textFieldClass = 'border border-gray-2 p-2 w-full rounded-[4px]';

export const GRAY_2 = '#7B7B7B';

export const GREEN = '#00BC73';

export const BLUE_2 = '#2828F0';

export const supportPackages = [
  {
    packageName: 'Basic',
    price: 'Free',
    tagline: '',
    pointOne: 'Limited billing cases only',
    pointTwo: 'Response within 24 hours',
    color: GRAY_2,
  },
  {
    packageName: 'Standard',
    price: '$29',
    tagline: 'Plus 4% of monthly charges',
    pointOne: 'Business hours support',
    pointTwo: 'Response time 4 hours',
    commitments: '*90 days commitment',
    color: GREEN,
  },
  {
    packageName: 'Enhanced',
    price: '$500',
    tagline: 'Plus 5% of monthly charges',
    pointOne: '24x7 support for all cases',
    pointTwo: 'Response time 1 hour',
    commitments: '*90 days commitment',
    color: BLUE_2,
  },
];

export const BASIC = 'Basic';

export const STANDARD = 'Standard';

export const ENHANCED = 'Enhanced';

export const Admin = 'admin';

export const User = 'user';

export const UserRoles = [User, Admin];

export const Months: { [key: number]: string } = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const PER_PAGE_LIMIT = 10;

export const PER_PAGE_LIMIT_USERS = 9;

export const MAX_FILE_SIZE = 10240; // 10mb

export enum Role {
  USER = 'user',
  ADMIN = 'admin',
}

export enum SubscriptionStatus {
  CANCELLED = 'cancelled',
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  PENDING = 'pending',
  PENDING_CANCELLATION = 'pending-cancellation',
}

export enum Moyassar3dSecureResponse {
  APPROVED = 'APPROVED',
  DECLINED_INVALID_PIN = '3-D Secure transaction attempt failed (DECLINED_INVALID_PIN)',
  DECLINED = 'DECLINED',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  UNSPECIFIED_FAILURE = 'UNSPECIFIED_FAILURE',
}

export const MoyassarErrorMessages: ErrorMessages = {
  [Moyassar3dSecureResponse.DECLINED_INVALID_PIN]: 'Wrong OTP',
  [Moyassar3dSecureResponse.DECLINED]: 'Card Declined',
  [Moyassar3dSecureResponse.INSUFFICIENT_FUNDS]: 'Insufficient Funds',
  [Moyassar3dSecureResponse.UNSPECIFIED_FAILURE]: 'Unspecified failure',
};

export const SUB_DURATION = 90;

// Invoice Status
export enum Status {
  UNPAID = 'pending',
  PAID = 'paid',
  FAILED = 'failed',
}

export const SaudiProvinces = [
  'Al-Bahah Province',
  'Al-Jawf Province',
  'Al-Qassim Province',
  'Asir Province',
  'Eastern Province',
  'Hail Province',
  'Jazan Province',
  'Mecca Province',
  'Medina Province',
  'Najran Province',
  'Northern Borders',
  'Riyadh Province',
  'Tabuk Province',
];

export const SaudiCities = [
  'Abha',
  'Al Ardiyat',
  'Al Bahah',
  'Al Duwadimi',
  'Al Hofuf',
  'Al Jubail',
  'Al Khobar',
  'Al Lith',
  'Al Namas',
  'Al Qaisumah',
  'Al Qatif',
  'Al Qurayyat',
  'Al Sulayyil',
  'Al Wajh',
  'Al-Kharj',
  'AlUla',
  'Ar Rass',
  'Arar',
  'Az Zulfi',
  'Badr',
  'Bisha',
  'Buqayq',
  'Buraydah',
  'Dammam',
  'Dhahran',
  'Duba',
  'Hafar Al Batin',
  'Hail',
  'Haql',
  'Jazan',
  'Jeddah',
  'Khafji',
  'Khamis Mushait',
  'Madinah',
  'Makkah',
  'Najran',
  'Rabigh',
  'Rafha',
  'Ras Tanura',
  'Riyadh',
  'Sabya',
  'Safwa',
  'Sakaka',
  'Shaqra',
  'Sharorah',
  'Tabuk',
  'Taif',
  'Tubarjal',
  'Turaif',
  'Unayzah',
  'Yanbu',
];

export const SaudiCityProvinces = [
  { city: 'Abha', province: 'Asir Province' },
  { city: 'Al Ardiyat', province: 'Mecca Province' },
  { city: 'Al Bahah', province: 'Al-Bahah Province' },
  { city: 'Al Duwadimi', province: 'Riyadh Province' },
  { city: 'Al Hofuf', province: 'Eastern Province' },
  { city: 'Al Jubail', province: 'Eastern Province' },
  { city: 'Al Khobar', province: 'Eastern Province' },
  { city: 'Al Lith', province: 'Mecca Province' },
  { city: 'Al Namas', province: 'Asir Province' },
  { city: 'Al Qaisumah', province: 'Eastern Province' },
  { city: 'Al Qatif', province: 'Eastern Province' },
  { city: 'Al Qurayyat', province: 'Al-Jawf Province' },
  { city: 'Al Sulayyil', province: 'Riyadh Province' },
  { city: 'Al Wajh', province: 'Tabuk Province' },
  { city: 'Al-Kharj', province: 'Riyadh Province' },
  { city: 'AlUla', province: 'Medina Province' },
  { city: 'Ar Rass', province: 'Al-Qassim Province' },
  { city: 'Arar', province: 'Northern Borders' },
  { city: 'Az Zulfi', province: 'Riyadh Province' },
  { city: 'Badr', province: 'Medina Province' },
  { city: 'Bisha', province: 'Asir Province' },
  { city: 'Buqayq', province: 'Eastern Province' },
  { city: 'Buraydah', province: 'Al-Qassim Province' },
  { city: 'Dammam', province: 'Eastern Province' },
  { city: 'Dhahran', province: 'Eastern Province' },
  { city: 'Duba', province: 'Tabuk Province' },
  { city: 'Hafar Al Batin', province: 'Eastern Province' },
  { city: 'Hail', province: 'Hail Province' },
  { city: 'Haql', province: 'Tabuk Province' },
  { city: 'Jazan', province: 'Jazan Province' },
  { city: 'Jeddah', province: 'Mecca Province' },
  { city: 'Khafji', province: 'Eastern Province' },
  { city: 'Khamis Mushait', province: 'Asir Province' },
  { city: 'Madinah', province: 'Medina Province' },
  { city: 'Makkah', province: 'Mecca Province' },
  { city: 'Najran', province: 'Najran Province' },
  { city: 'Rabigh', province: 'Mecca Province' },
  { city: 'Rafha', province: 'Northern Borders' },
  { city: 'Ras Tanura', province: 'Eastern Province' },
  { city: 'Riyadh', province: 'Riyadh Province' },
  { city: 'Sabya', province: 'Jazan Province' },
  { city: 'Safwa', province: 'Eastern Province' },
  { city: 'Sakaka', province: 'Al-Jawf Province' },
  { city: 'Shaqra', province: 'Riyadh Province' },
  { city: 'Sharorah', province: 'Najran Province' },
  { city: 'Tabuk', province: 'Tabuk Province' },
  { city: 'Taif', province: 'Mecca Province' },
  { city: 'Tubarjal', province: 'Al-Jawf Province' },
  { city: 'Turaif', province: 'Northern Borders' },
  { city: 'Unayzah', province: 'Al-Qassim Province' },
  { city: 'Yanbu', province: 'Medina Province' },
];

export const OrganizationTypes = [
  'Private Sector',
  'Non-profit/Charity',
  'Government/Public Sector',
  'Semi-Government',
  'Independent',
];

export const BILLING_STATUS = {
  PENDING: 'pending',
  FAILED: 'failed',
  PAID: 'paid',
};

export interface SupportFormData {
  subject: string;
  email: string;
  message: string;
  name: string;
  file: File | null;
}

export const NAME_REGEX = /[^a-zA-Z0-9\s.-]|-{2,}|\.{2,}|\s{2,}|\.\s|\s\.|\s-|-\s|\.-|-\./g;

export const ACCOUNT_NAME_REGEX = /[.\-\s]+$/g;

export enum NafathRequestStatus {
  PENDING = 'pending',
  WAITING = 'waiting',
  EXPIRED = 'expired',
  REJECTED = 'rejected',
  COMPLETED = 'completed',
  ERROR = 'error',
  CANCELLED = 'cancelled',
  STATUS_WATING = 'status-waiting',
}

export const NAFATH_VERIFICATION_SECONDS = 180;

export enum StatusReason {
  BUDGET_OVERAGE = 'budget-overage',
  FAILED_PAYMENT = 'failed-payment',
  SECURITY = 'security',
  PENDING_REVIEW = 'pending-review',
  FAILED_SETTLEMENT = 'failed-settlement',
  TERMS_VIOLATION = 'terms-violation',
}

export enum ToastStatus {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum InvoiceType {
  MONTHLY = 'monthly',
  THRESHOLD = 'threshold',
  SETTLEMENT = 'settlement',
}
