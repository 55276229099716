import { useEffect, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DropdownIcon from '../../../Assets/Images/svg/dropdownIcon.svg';
import GreenTickIcon from '../../../Assets/Images/svg/greenTick.svg';
import LogoSection from '../../../Components/Common/LogoSection';
import Spinner from '../../../Components/Common/Spinner';
import Stepper from '../../../Components/Common/Stepper';
import StepperMobile from '../../../Components/Common/StepperMobile';
import { updateCrnError } from '../../../Reducers/signupReducer';
import { AppDispatch, RootState } from '../../../Store';
import { FieldErrors } from '../../../Types';
import {
  fetchBusinessInformationAsync,
  verifyCompanyRegistrationNumberAsync,
} from '../../../Utils/api';
import {
  ACCOUNT_NAME_REGEX,
  BUSINESS,
  NAME_REGEX,
  OrganizationTypes,
  SaudiCities,
  SaudiCityProvinces,
  SaudiProvinces,
  textFieldClass,
} from '../../../Utils/constants';
import {
  convertArabicNumerals,
  splitAccountName,
  validateEmail,
} from '../../../Utils/helperFunctions';
import ButtonsSection from '../Common/ButtonsSection';
import SelectField from '../Common/SelectField';

const BusinessInformation = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const crnErrorMessage = useSelector((state: RootState) => state.signup?.crnError);
  const customerDetails = useSelector((state: RootState) => state.signup.customer?.customer);
  const [isLoading, setIsLoading] = useState(false);
  const [resetVatId, setResetVatId] = useState(false);
  const [isCrVerifying, setIsCrVerifying] = useState(false);
  const [isCrVerificationStarted, setIsCrVerificationStarted] = useState(false);
  const [isCrVerified, setIsCrVerified] = useState(!!customerDetails?.identificationNumber);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    businessName: customerDetails?.businessName,
    arabicBusinessName: customerDetails?.arabicBusinessName,
    organizationType: customerDetails?.organizationType ?? 'private',
    identificationNumber: customerDetails?.identificationNumber ?? '',
    accountName: customerDetails?.accountName ?? 'cntxt-online-CR-',
    vatId: customerDetails?.vatId,
    address: customerDetails?.address,
    arabicAddress: customerDetails?.arabicAddress,
    city: customerDetails?.city ?? '',
    province: customerDetails?.province ?? '',
    firstName: customerDetails?.user?.firstName,
    lastName: customerDetails?.user?.lastName,
    contactEmail: customerDetails?.contactEmail,
    domain: customerDetails?.domain ?? customerDetails?.user?.email?.split('@')[1],
    phone: customerDetails?.user?.phone,
    postalCode: customerDetails?.postalCode,
  });

  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({
    businessName: false,
    arabicBusinessName: false,
    organizationType: false,
    identificationNumber: false,
    accountName: false,
    address: false,
    arabicAddress: false,
    city: false,
    province: false,
    firstName: false,
    lastName: false,
    phone: false,
    postalCode: false,
    contactEmail: false,
  });
  const [isError, setIsError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === 'province') {
      setFormData((prevState) => ({
        ...prevState,
        city: '',
        [name]: value,
      }));
    } else if (name === 'city') {
      const selectedProvince =
        SaudiCityProvinces.find((city) => city.city === value)?.province ?? '';
      setFormData((prevState) => ({
        ...prevState,
        city: value,
        province: selectedProvince,
      }));
    } else if (name === 'vatId') {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      if (resetVatId) {
        setResetVatId(false);
      }
    } else {
      const sanitizedValue = value.replace(/[\u0600-\u06FF]/g, '');
      setFormData((prevState) => ({
        ...prevState,
        [name]: sanitizedValue,
      }));
    }
  };

  const handleCheckboxChange = () => {
    setResetVatId((prevState) => {
      const newState = !prevState;
      if (newState) {
        setFormData((prevData) => ({ ...prevData, vatId: '' }));
      }
      return newState;
    });
  };

  useEffect(() => {
    if (resetVatId) {
      setFormData((prevData) => ({ ...prevData, vatId: '' }));
    }
  }, [resetVatId]);

  const handleSubmit = () => {
    let errorMsg = '';
    const errors: FieldErrors = {
      businessName: !formData.businessName,
      arabicBusinessName: !formData.arabicBusinessName,
      organizationType: !formData.organizationType,
      identificationNumber: !formData.identificationNumber || !isCrVerified,
      accountName: !formData.accountName,
      address: !formData.address,
      arabicAddress: !formData.arabicAddress,
      city: !formData.city,
      province: !formData.province,
      firstName: !formData.firstName,
      lastName: !formData.lastName,
      phone: !formData.phone || !isValidPhoneNumber(formData.phone),
      postalCode: !formData.postalCode,
      contactEmail: !formData.contactEmail,
    };
    setFieldErrors(errors);

    if (!isCrVerified) {
      dispatch(updateCrnError('Please verify CR number'));
    }

    const hasErrors = Object.values(errors).some((error) => error);
    setIsError(hasErrors);
    if (hasErrors) {
      errorMsg = 'Please fill all required fields';
    }
    if (crnErrorMessage) {
      errorMsg = 'Please resolve all issues to move forward';
    }
    setErrorMessage(errorMsg);

    if (!hasErrors && !crnErrorMessage && formData.postalCode?.toString().length === 5) {
      setErrorMessage('');
      dispatch(fetchBusinessInformationAsync(formData, setIsLoading, setErrorMessage, navigate));
    }
  };

  const getInputClass = (fieldName: keyof FieldErrors): string => {
    return `${textFieldClass} ${fieldErrors[fieldName] ? 'border-red-0' : ''}`;
  };

  const handleBusinessNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const accountNamePrefix = 'cntxt-online-';
    const initialAccountName = `${accountNamePrefix}${formData.identificationNumber}-`;
    const inputValue = e.target.value;
    let businessName = inputValue.replace(NAME_REGEX, '');

    const availableLength = 60 - initialAccountName.length;
    businessName = businessName.substring(0, availableLength);
    const accountName = initialAccountName + businessName;

    setFormData((prevState) => ({
      ...prevState,
      businessName,
      accountName: accountName
        ?.toLowerCase()
        ?.replaceAll(' ', '-')
        ?.replace(ACCOUNT_NAME_REGEX, ''),
    }));
  };

  const handleArabicBusinessNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const arabicBusinessName = e.target.value;
    const updatedBusinessName = arabicBusinessName.replace(
      /[^\u0600-\u06FF0-9\s-.]|-{2,}|\.{2,}|\.-|-\./g,
      '',
    );
    setFormData((prevState) => ({
      ...prevState,
      arabicBusinessName: updatedBusinessName,
    }));
  };

  const handleArabicAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const arabicAddress = e.target.value;
    const updatedArabicAddress = arabicAddress.replace(
      /[^\u0600-\u06FF0-9\s-.]|-{2,}|\.{2,}|\.-|-\./g,
      '',
    );
    setFormData((prevState) => ({
      ...prevState,
      arabicAddress: updatedArabicAddress,
    }));
  };

  const handleDomainNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const updatedValue = value.replace(/(^-)|([^a-zA-Z0-9-])|(-{2,})/g, '');

    const combinedDomain = updatedValue
      ? `${updatedValue}.${customerDetails?.user?.email?.split('@')[1]}`
      : customerDetails?.user?.email?.split('@')[1];

    setFormData((prevState) => ({
      ...prevState,
      [name]: combinedDomain,
    }));
  };

  const updateDomainValue = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    let updatedValue = value;
    if (updatedValue.endsWith('-')) {
      updatedValue = updatedValue.slice(0, -1);
    }

    const combinedDomain = updatedValue
      ? `${updatedValue}.${customerDetails?.user?.email?.split('@')[1]}`
      : customerDetails?.user?.email?.split('@')[1];
    setFormData((prevState) => ({
      ...prevState,
      [name]: combinedDomain,
    }));
  };

  const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const crNumber = e.target.value;
    const accountNamePrefix = 'cntxt-online-';
    const prefixAndCrNumberLength = `${accountNamePrefix}${crNumber}-`.length;

    const maxBusinessNameLength = 60 - prefixAndCrNumberLength;

    let trimmedBusinessName = (formData.businessName ?? '').toLowerCase().replaceAll(' ', '-');
    if (trimmedBusinessName.length > maxBusinessNameLength) {
      trimmedBusinessName = trimmedBusinessName.substring(0, maxBusinessNameLength);
    }

    const accountName = `${accountNamePrefix}${crNumber}-${trimmedBusinessName}`;

    setFormData((prevState) => ({
      ...prevState,
      identificationNumber: crNumber,
      accountName,
    }));

    if (crNumber.length < 10) {
      setIsCrVerified(false);
      dispatch(updateCrnError(''));
    }
  };

  const handlePhoneChange = (number: string) => {
    setFormData((prevState) => ({
      ...prevState,
      phone: number,
    }));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const sanitizedValue = value.split('@')[0].replace(/[^a-zA-Z0-9.]|\.{2,}/g, '');
    const email = `${sanitizedValue}@${formData.domain}`;
    setFormData((prevState) => ({
      ...prevState,
      [name]: email,
    }));
    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
    } else {
      setErrorMessage('');
    }
  };

  const verifyCrNumber = () => {
    const crNumber = formData.identificationNumber || '';
    if (crNumber) {
      setIsCrVerificationStarted(true);
      dispatch(
        verifyCompanyRegistrationNumberAsync(crNumber, setIsCrVerified, setIsCrVerifying, navigate),
      );
      setIsCrVerificationStarted(false);
      setFieldErrors((prev) => ({
        ...prev,
        identificationNumber: false,
      }));
    }
  };

  const renderCrVerificationStatus = () => {
    if (isCrVerifying) {
      return <Spinner adjustContainerHeight />;
    } else if (!isCrVerificationStarted && crnErrorMessage) {
      return 'Verify';
    } else if (isCrVerified && !crnErrorMessage) {
      return <img className="w-7 h-7" src={GreenTickIcon} alt="Verified" />;
    } else {
      return 'Verify';
    }
  };

  function getErrorMessage(isError: boolean, errorMessage: string): string {
    let finalErrorMessage = '';
    if (isError || errorMessage) {
      finalErrorMessage = errorMessage;
    }

    return finalErrorMessage;
  }

  function splitDomain() {
    const str = formData?.domain;
    const dotCount = (str.match(/\./g) || []).length;

    if (dotCount === 1) {
      return ''; //no subdomain added return empty string
    }
    const firstDotIndex = str.indexOf('.');

    const subDomain = str.substring(0, firstDotIndex);
    return subDomain;
  }

  const onAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    let sanitizedValue = inputValue.replace(NAME_REGEX, '');
    const accountName = splitAccountName(formData.accountName, true);
    if (accountName?.substringBefore) {
      const maxAccountNameLength = 60 - accountName?.substringBefore.length;
      sanitizedValue = sanitizedValue.substring(0, maxAccountNameLength);
    }
    setFormData((prevState) => ({
      ...prevState,
      accountName: `${accountName?.substringBefore}${sanitizedValue}`
        ?.toLowerCase()
        ?.replaceAll(' ', '-')
        ?.replace(ACCOUNT_NAME_REGEX, ''),
    }));
  };

  return (
    <div className="w-full h-full flex items-stretch lg:items-center justify-center">
      {isLoading && <Spinner />}
      {!isLoading && (
        <div className="border-[1px] border-gray-3 rounded-lg py-4 px-8 bg-white-0 min-h-[680px] w-[98%] overflow-auto flex flex-col justify-between">
          <LogoSection />
          <Stepper type={BUSINESS} currentStep={1} />
          <StepperMobile
            stepNumber={1}
            stepName="Business Information"
            nextStep="Support Package"
            totalSteps={5}
          />
          <div className="flex flex-col gap-y-4 md:gap-y-2">
            <div className="flex flex-col md:flex-row w-full gap-3 md:gap-12 mb-4">
              <div className="w-full md:w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    Business Name
                  </h3>
                  <p className="text-red-0">*</p>
                </div>

                <input
                  className={`py-4 ${getInputClass('businessName')}`}
                  name="businessName"
                  type="text"
                  placeholder="Business Name"
                  value={formData.businessName ?? ''}
                  onChange={handleBusinessNameChange}
                  maxLength={150}
                />
              </div>
              <div className="w-full md:w-[90%] poppins-500" dir="rtl" lang="ar">
                <div className="flex gap-1 items-center">
                  <h3
                    className="text-right text-sm font-medium leading-loose text-gray-1"
                    style={{ fontFamily: "'Amiri', serif" }}
                  >
                    الاسم التجاري باللغة العربية
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${getInputClass('arabicBusinessName')}`}
                  style={{ fontFamily: "'Amiri', serif" }}
                  name="arabicBusinessName"
                  type="text"
                  placeholder="الاسم التجاري"
                  value={formData.arabicBusinessName ?? ''}
                  onChange={handleArabicBusinessNameChange}
                  maxLength={150}
                />
              </div>
              <div className="w-full md:w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm font-medium leading-loose text-gray-1">
                    Business Domain
                  </h3>
                  <span className="font-thin"> (sub-domain - optional)</span>
                </div>
                <div className="relative">
                  <div
                    className={`flex items-center border border-gray-2 p-2 w-full rounded-[4px] focus-within:border-transparent focus-within:outline-none focus-within:ring-2 focus-within:ring-[#2563eb] relative [&_input]:outline-none`}
                  >
                    <div className="flex w-full text-right md:whitespace-nowrap overflow-hidden">
                      <input
                        className="text-right flex-grow py-2"
                        name="domain"
                        type="text"
                        placeholder="_______________  "
                        value={splitDomain()}
                        onChange={handleDomainNameChange}
                        onBlur={updateDomainValue}
                        maxLength={30}
                        style={{ width: 'inherit' }}
                      />
                      <span className="text-gray-5 opacity-80 pt-2">
                        {`.${customerDetails?.user?.email?.split('@')[1]}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full gap-3 md:gap-12 mb-4">
              <div className="w-full md:w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    Organization Type
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                {/*  Add a function to handle the change in value for this field  */}
                <SelectField
                  options={OrganizationTypes}
                  value={formData.organizationType || ''}
                  onChange={handleChange}
                  className="py-4 bg-white-0"
                  name="organizationType"
                  dropdownIcon={DropdownIcon}
                />
              </div>

              <div className="w-full md:w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <div className="flex gap-2">
                    <h3 className="text-left text-sm font-medium leading-loose text-gray-1">
                      CR Number
                    </h3>
                    <h3
                      className="text-left text-sm font-medium leading-loose text-gray-1"
                      style={{ fontFamily: "'Amiri', serif" }}
                    >
                      (رقم سجل التجاري)
                    </h3>
                  </div>

                  <p className="text-red-0">*</p>
                </div>
                <div className="relative flex">
                  <input
                    className={`pr-14 py-4 [&::-webkit-outer-spin-button]:hidden [&::-webkit-inner-spin-button]:hidden ${
                      crnErrorMessage && 'border-red-0'
                    } ${!crnErrorMessage && 'border-gray-2'} ${getInputClass(
                      'identificationNumber',
                    )} [&::-webkit-outer-spin-button]:hidden [&::-webkit-inner-spin-button]:hidden`}
                    name="identificationNumber"
                    type="text"
                    placeholder="Company Registration Number"
                    value={formData.identificationNumber || ''}
                    style={{ MozAppearance: 'textfield' }}
                    onChange={handleIdChange}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.slice(0, 10);
                      e.target.value = convertArabicNumerals(e.target.value);
                    }}
                  />

                  <button
                    className={`w-16 pt-4 h-10 text-gray-1 underline absolute top-0 right-0 text-[16px] underline-offset-4 font-normal ${
                      formData.identificationNumber?.length !== 10 && 'opacity-30'
                    } ${
                      formData.identificationNumber?.length === 10 && 'opacity-70 cursor-pointer'
                    }`}
                    onClick={() => verifyCrNumber()}
                    disabled={formData.identificationNumber?.length !== 10 || isCrVerified}
                  >
                    <div className="flex justify-center items-center">
                      {renderCrVerificationStatus()}
                    </div>
                  </button>

                  {formData.identificationNumber && crnErrorMessage && (
                    <p className="text-red-0 text-xs absolute top-[105%] right-0 left-0">
                      {crnErrorMessage}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-full md:w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm font-medium leading-loose text-gray-1">
                    VAT ID
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`border border-gray-2 p-2 w-full rounded-[4px] [&::-webkit-outer-spin-button]:hidden py-4 [&::-webkit-inner-spin-button]:hidden ${
                    formData.vatId && formData.vatId.length !== 15
                      ? 'border-red-0'
                      : 'border-gray-2'
                  }`}
                  name="vatId"
                  type="text"
                  value={formData.vatId ?? ''}
                  style={{ MozAppearance: 'textfield' }}
                  placeholder="VAT ID"
                  onChange={handleChange}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.slice(0, 15);
                    e.target.value = convertArabicNumerals(e.target.value);
                  }}
                  maxLength={15}
                />
                <div className="flex items-center mt-2">
                  <input
                    className="mr-2 custom-checkbox"
                    type="checkbox"
                    id="resetVatIdCheckbox"
                    checked={resetVatId}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="resetVatIdCheckbox" className="text-sm">
                    Click here if your company does not have VAT ID.
                  </label>
                </div>
                {formData.vatId && formData.vatId.length !== 15 && (
                  <p className="text-red-0 text-xs">VAT ID should be 15 characters long</p>
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full gap-3 md:gap-8 mb-4">
              <div className="w-full poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    Account Name
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <div className="w-full md:w-[31%] relative">
                  <div
                    className={`flex items-center relative ${getInputClass(
                      'accountName',
                    )} focus-within:border-transparent focus-within:outline-none focus-within:ring-2 focus-within:ring-[#2563eb] relative [&_input]:outline-none `}
                  >
                    <div className="flex w-full text-left md:whitespace-nowrap  [&::-webkit-scrollbar]:hidden">
                      <span className="w-fit text-gray-5 opacity-80 text-nowrap pt-2">
                        {splitAccountName(formData.accountName || '', true)?.substringBefore}
                      </span>
                      <input
                        className="box-border md:w-full overflow-auto py-2"
                        name="accountName"
                        type="text"
                        placeholder="[Business Name]"
                        value={splitAccountName(formData.accountName || '', true)?.substringAfter}
                        onChange={onAccountNameChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full gap-3 md:gap-8 mb-4">
              <div className="w-full md:w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    Street Address
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${getInputClass('address')}`}
                  name="address"
                  type="text"
                  placeholder="Street Address"
                  value={formData.address ?? ''}
                  onChange={handleChange}
                  maxLength={150}
                />
              </div>
              <div className="w-full md:w-[90%] poppins-500" dir="rtl" lang="ar">
                <div className="flex gap-1 items-center">
                  <h3
                    className="text-right text-sm font-medium leading-loose text-gray-1"
                    style={{ fontFamily: "'Amiri', serif" }}
                  >
                    عنوان الشارع
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${getInputClass('arabicAddress')}`}
                  style={{ fontFamily: "'Amiri', serif" }}
                  name="arabicAddress"
                  type="text"
                  placeholder="عنوان الشارع"
                  value={formData.arabicAddress ?? ''}
                  onChange={handleArabicAddressChange}
                  maxLength={50}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full gap-3 md:gap-8 mb-4">
              <div className="w-full md:w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    City
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <SelectField
                  className={`py-4 ${getInputClass('city')} bg-white-0`}
                  options={SaudiCities}
                  value={formData.city || ''}
                  onChange={handleChange}
                  name="city"
                  dropdownIcon={DropdownIcon}
                />
              </div>
              <div className="w-full md:w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm font-medium leading-loose text-gray-1">
                    Postal Code
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={
                    getInputClass('postalCode') +
                    ` [&::-webkit-outer-spin-button]:hidden py-4 [&::-webkit-inner-spin-button]:hidden ${
                      formData.postalCode?.toString().length !== 0 &&
                      formData.postalCode?.toString().length !== 5 &&
                      formData.postalCode !== null
                        ? 'border-red-0'
                        : 'border-gray-2'
                    }`
                  }
                  type="text"
                  name="postalCode"
                  style={{ MozAppearance: 'textfield' }}
                  value={formData.postalCode ?? ''}
                  placeholder="Postal Code"
                  onChange={handleChange}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.slice(0, 5);
                    e.target.value = convertArabicNumerals(e.target.value);
                  }}
                />
                {formData.postalCode?.toString().length !== 0 &&
                  formData.postalCode?.toString().length !== 5 &&
                  formData.postalCode !== null && (
                    <p className="text-red-0 text-xs">Postal code should have 5 digits</p>
                  )}
              </div>
              <div className="w-full md:w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    Province
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <SelectField
                  className={`py-4 ${getInputClass('province')} bg-white-0`}
                  options={SaudiProvinces}
                  value={formData.province}
                  onChange={handleChange}
                  name="province"
                  dropdownIcon={DropdownIcon}
                />
              </div>
              <div className="w-full md:w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm font-medium leading-loose text-gray-1">
                    Country
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${textFieldClass} bg-gray-4 text-gray-1 text-opacity-70`}
                  type="text"
                  value="Saudi Arabia"
                  disabled
                  maxLength={40}
                />
              </div>
            </div>

            <div className="flex gap-2 items-center mt-4 mb-4">
              <h1 className="text-gray-1 text-lg poppins-700 font-[20px] whitespace-nowrap">
                Contact Information
              </h1>
              <div className="w-full h-[1px] bg-gray-2"></div>
            </div>

            <div className="flex flex-col md:flex-row w-full gap-3 md:gap-8">
              <div className="w-full md:w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    First Name
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${getInputClass('firstName')}`}
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  value={formData.firstName || ''}
                  onChange={handleChange}
                  maxLength={40}
                />
              </div>
              <div className="w-full md:w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm font-medium leading-loose text-gray-1">
                    Last Name
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${getInputClass('lastName')}`}
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  value={formData.lastName || ''}
                  onChange={handleChange}
                  maxLength={40}
                />
              </div>
              <div className="w-full md:w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    Email
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <div className="relative">
                  <div
                    className={`flex items-center border border-gray-2 p-2 w-full rounded-[10px] focus-within:border-transparent focus-within:outline-none focus-within:ring-2 focus-within:ring-[#2563eb] relative [&_input]:outline-none`}
                  >
                    <div className="flex w-full text-right md:whitespace-nowrap overflow-hidden">
                      <input
                        className="text-right flex-grow py-2"
                        type="text"
                        name="contactEmail"
                        maxLength={70}
                        placeholder="_______________  "
                        value={formData.contactEmail.split('@')[0] || ''}
                        onChange={handleEmailChange}
                        style={{ width: 'inherit' }}
                      />
                      <span className="text-gray-5 opacity-80 pt-2">
                        {`@${customerDetails?.user?.email?.split('@')[1]}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm font-medium leading-loose text-gray-1">Phone</h3>
                  <p className="text-red-0">*</p>
                </div>
                <div
                  className={`${getInputClass(
                    'phone',
                  )} focus-within:border-0 focus-within:outline-none focus-within:ring-2 focus-within:ring-[#2563eb] relative [&_input]:outline-none [&>div>div>.PhoneInputCountryIcon]:h-5 [&>div>div>.PhoneInputCountryIcon]:w-8 `}
                >
                  <PhoneInput
                    defaultCountry="SA"
                    name="phone"
                    className="py-2"
                    placeholder="Phone number"
                    value={formData.phone ?? ''}
                    onChange={handlePhoneChange}
                    limitMaxLength
                  />
                  {formData.phone && isValidPhoneNumber(formData.phone) === false && (
                    <p className="text-red-0 text-xs absolute top-[105%] right-0 left-0">
                      Invalid Phone Number
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <ButtonsSection
            errorMessage={getErrorMessage(isError, errorMessage)}
            isBackButton={false}
            isNextButton={true}
            nextFunction={handleSubmit}
          />
        </div>
      )}
    </div>
  );
};

export default BusinessInformation;
