// This file will be used for axios set up
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { fetchAdminDetails } from '../../Reducers/adminReducer';
import { addCardDetails, addCards, removeCard, updateCard } from '../../Reducers/cardReducer';
import { CustomersState, fetchCustomers } from '../../Reducers/customersReducer';
import { fetchDashboardStats } from '../../Reducers/dashboardReducer';
import { fetchInvoices } from '../../Reducers/invoicesReducer';
import {
  fetchCustomerDetails,
  updateCrnError,
  updateNafathStatus,
  updateOnboardingStep,
} from '../../Reducers/signupReducer';
import { fetchSubscriptionDetails } from '../../Reducers/subscriptionReducer';
import { fetchURLs } from '../../Reducers/urlsReducer';
import { fetchUsers } from '../../Reducers/usersReducer';
import { AppDispatch, RootState } from '../../Store';
import {
  BusinessData,
  Card,
  ConfigURLsAPIResponse,
  CustomerResult,
  DashboardProps,
  DeleteUsersParams,
  IndividualData,
  InviteUserParams,
  InvoicesState,
  IqamaApiResponse,
  NafathApiResponse,
  NafathResponseBody,
  NafathStatusApiResponse,
  SaveCardDetailsParams,
  SetErrorMessageType,
  SetIsLoadingType,
  SetState,
  SubscriptionProps,
  UsersState,
} from '../../Types';
import {
  BUSINESS,
  INDIVIDUAL,
  NafathRequestStatus,
  NAFATH_VERIFICATION_SECONDS,
  Navigation,
  ONBOARDING_STEPS,
  Role,
  SupportFormData,
  TrackStep,
} from '../constants';
import {
  constructApiUrl,
  getTotalSecondsFromCreatedAt,
  handleLogoutAndReset,
  isUnauthorizedError,
} from '../helperFunctions';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const getConfig = (token: string) => ({
  headers: { Authorization: `Bearer ${token}` },
});

export const fetchCustomerDetailsAsync = (
  accountType: string,
  navigate: ReturnType<typeof useNavigate>,
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  referrer?: string,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;

    setIsLoading(true);

    api
      .post('v1/customers/login', { accountType, ...(referrer && { referrer }) }, getConfig(token))
      .then((result: CustomerResult) => {
        const step = result?.data?.customer?.step;
        const role = result?.data?.customer?.user?.role;
        setIsLoading(false);

        if (role === Role.USER) {
          dispatch(fetchCustomerDetails(result.data));

          const stepActions = {
            [TrackStep.GOOGLE]: () =>
              dispatch(updateOnboardingStep(ONBOARDING_STEPS.INITIAL_DETAILS)),
            [TrackStep.INFO]: () =>
              dispatch(
                updateOnboardingStep(
                  accountType === INDIVIDUAL
                    ? ONBOARDING_STEPS.CONTACT_INFORMATION
                    : ONBOARDING_STEPS.BUSINESS_INFORMATION,
                ),
              ),
            [TrackStep.PACKAGE]: () =>
              dispatch(updateOnboardingStep(ONBOARDING_STEPS.SUPPORT_PACKAGE)),
            [TrackStep.DOCUMENT]: () =>
              dispatch(updateOnboardingStep(ONBOARDING_STEPS.UPLOAD_DOCUMENTS)),
            [TrackStep.BILLING]: () =>
              dispatch(updateOnboardingStep(ONBOARDING_STEPS.BILLING_EMAIL)),
            [TrackStep.PAYMENT]: () =>
              dispatch(updateOnboardingStep(ONBOARDING_STEPS.PAYMENT_INFORMATION)),
            [TrackStep.TERMS]: () => dispatch(updateOnboardingStep(ONBOARDING_STEPS.TERMS)),
            [TrackStep.SUCCESS]: () => dispatch(updateOnboardingStep(ONBOARDING_STEPS.SUCCESS)),
            [TrackStep.COMPLETED]: () => navigate(Navigation.DASHBOARD),
          };

          if (stepActions[step as keyof typeof stepActions]) {
            stepActions[step as keyof typeof stepActions]();
          }

          if (step !== TrackStep.COMPLETED) {
            navigate('/signup');
          }
        } else {
          dispatch(fetchAdminDetails(result.data));
          navigate(Navigation.MANAGE_CUSTOMERS);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        } else {
          setErrorMessage(error?.response?.data?.message?.message || 'Unable to fetch users data.');
        }
      });
  };
};

export const fetchBusinessInformationAsync = (
  formData: BusinessData | IndividualData,
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;

    setIsLoading(true);

    api
      .post('v1/customers/basic-info', formData, getConfig(token))
      .then((result: CustomerResult) => {
        setIsLoading(false);
        dispatch(fetchCustomerDetails(result.data));
        dispatch(updateOnboardingStep(ONBOARDING_STEPS.SUPPORT_PACKAGE));
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message ||
              error?.response?.data?.message ||
              'Unable to save the information on backend.',
          );
        }
      });
  };
};

export const updateSupportPackageAsync = (
  supportPackage: string,
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    const accountType = state.signup.accountType;
    setIsLoading(true);

    api
      .post('v1/customers/support-package', { package: supportPackage }, getConfig(token))
      .then((result: CustomerResult) => {
        setIsLoading(false);
        dispatch(fetchCustomerDetails(result.data));
        if (accountType === BUSINESS) {
          dispatch(updateOnboardingStep(ONBOARDING_STEPS.UPLOAD_DOCUMENTS));
        } else {
          dispatch(fetchPaymentMethodData(setIsLoading, setErrorMessage, navigate, false));

          dispatch(updateOnboardingStep(ONBOARDING_STEPS.PAYMENT_INFORMATION));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message ||
              'Unable to update the support package. Please try again',
          );
        }
      });
  };
};

export const updateInitialDetailsStepAsync = (
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    const accountType = state.signup.accountType;
    setIsLoading(true);

    api
      .post('v1/customers/google-info', {}, getConfig(token))
      .then((result: CustomerResult) => {
        setIsLoading(false);
        dispatch(fetchCustomerDetails(result.data));
        if (accountType === BUSINESS) {
          dispatch(updateOnboardingStep(ONBOARDING_STEPS.BUSINESS_INFORMATION));
        } else {
          dispatch(updateOnboardingStep(ONBOARDING_STEPS.CONTACT_INFORMATION));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message || 'The API failed while saving account type.',
          );
        }
      });
  };
};

export const createBillingGroupAsync = (
  gcpSecurityGroup: string,
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    const accountType = state.signup.accountType;
    setIsLoading(true);

    api
      .post('v1/customers/billing-group', { gcpSecurityGroup }, getConfig(token))
      .then((result: CustomerResult) => {
        setIsLoading(false);
        dispatch(fetchCustomerDetails(result.data));
        if (accountType === BUSINESS) {
          dispatch(fetchPaymentMethodData(setIsLoading, setErrorMessage, navigate, false));
          dispatch(updateOnboardingStep(ONBOARDING_STEPS.PAYMENT_INFORMATION));
        } else {
          dispatch(updateOnboardingStep(ONBOARDING_STEPS.CONTACT_INFORMATION));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message ||
              'The API failed while saving the users billing group.',
          );
        }
      });
  };
};

export const acceptTermsAndConditionsAsync = (
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;

    setIsLoading(true);

    api
      .post('v1/customers/terms-conditions', {}, getConfig(token))
      .then((result: CustomerResult) => {
        setIsLoading(false);
        dispatch(fetchCustomerDetails(result.data));
        dispatch(updateOnboardingStep(ONBOARDING_STEPS.SUCCESS));
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message || 'The API failed while accepting the terms.',
          );
        }
      });
  };
};

export const updateOnboardingSuccessAsync = (
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;

    setIsLoading(true);

    api
      .post('v1/customers/onboarding-complete', {}, getConfig(token))
      .then((result: CustomerResult) => {
        dispatch(fetchCustomerDetails(result.data));
        navigate(Navigation.DASHBOARD);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message ||
              'The API failed while updating the onboarding status.',
          );
        }
      });
  };
};

export const addFileAttachmentsAsync = (
  formData: FormData,
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);

    api
      .post('v1/customers/attachments', formData, getConfig(token))
      .then((result: CustomerResult) => {
        setIsLoading(false);
        dispatch(fetchCustomerDetails(result.data));
        dispatch(updateOnboardingStep(ONBOARDING_STEPS.BILLING_EMAIL));
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        } else {
          setErrorMessage(
            error?.response?.data?.message?.message ||
              'The API failed while uploading the documents.',
          );
        }
      });
  };
};

export const verifyCompanyRegistrationNumberAsync = (
  crNumber: string,
  setIsCrVerified: SetIsLoadingType,
  setIsVerifying: SetIsLoadingType,
  navigate: ReturnType<typeof useNavigate>,
  formData?: BusinessData | IndividualData,
  setIsLoading?: SetIsLoadingType,
  setErrorMessage?: SetErrorMessageType,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    formData && setIsLoading && setIsLoading(true);
    setIsVerifying(true);
    api
      .get(`wathq/${crNumber}`, getConfig(token))
      .then(() => {
        if (formData && setIsLoading && setErrorMessage) {
          dispatch(
            fetchBusinessInformationAsync(formData, setIsLoading, setErrorMessage, navigate),
          );
        }
        setIsCrVerified(true);
        setIsVerifying(false);
        dispatch(updateCrnError(''));
      })
      .catch((error) => {
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        }
        setIsLoading && setIsLoading(false);
        setIsCrVerified(false);
        setIsVerifying(false);
        dispatch(
          updateCrnError(error.response?.data?.message ?? 'Request Failed. Please try again!'),
        );
      });
  };
};

export const verifyIqamaIdAsync = (
  iqamaId: string,
  setIsCrVerified: SetIsLoadingType,
  setIsVerifying: SetIsLoadingType,
  setIsCrVerificationFailed: SetIsLoadingType,
  navigate: ReturnType<typeof useNavigate>,
  setIqamaErrorMessage: SetErrorMessageType,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsVerifying(true);
    api
      .get(`v1/customers/validate/iqama/${iqamaId}`, getConfig(token))
      .then(() => {
        setIsCrVerified(true);
        setIsVerifying(false);
        setIsCrVerificationFailed(false);
      })
      .catch((error) => {
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        }
        setIsCrVerified(false);
        setIsVerifying(false);
        setIsCrVerificationFailed(true);
        setIqamaErrorMessage(
          error?.response?.data?.message?.message || 'Account with same IqamaId already exists',
        );
      });
  };
};

export const fetchPaymentMethodData = (
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  isAdmin: boolean,
  customerId?: number,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);
    const url = constructApiUrl('v1/payment-methods', isAdmin, customerId);
    api
      .get(url, getConfig(token))
      .then((result: { data: { paymentMethods: Card[] } }) => {
        setIsLoading(false);
        dispatch(addCards(result.data.paymentMethods));
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message || 'API failed while fetching payment details',
          );
        }
      });
  };
};

export const saveCardDetails = ({
  paymentId,
  id,
  last4,
  expiryDate,
  status,
  type,
  navigate,
  setIsLoading,
  setErrorMessage,
  setErrorDescription,
  isAdmin,
  customerId,
  isOnboarding,
  setIsModalOpen,
  cardHolderName,
}: SaveCardDetailsParams) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;

    const body = {
      paymentId,
      status,
      paymentIdentifier: id,
      last4,
      expiryDate,
      paymentMethod: 'credit-card',
      type: type || 'visa',
      isOnboarding,
      cardHolderName,
    };
    const url = constructApiUrl('v1/payment-methods', isAdmin, customerId);
    api
      .post(url, body, getConfig(token))
      .then((result: { data: { paymentMethod: Card } }) => {
        const { id, status, paymentIdentifier, last4, expiryDate, type, cardHolderName } =
          result.data.paymentMethod;
        const cardCredentials = {
          id,
          status,
          paymentIdentifier,
          last4,
          expiryDate,
          type,
          cardHolderName,
        };
        if (isOnboarding) {
          dispatch(updateOnboardingStep(ONBOARDING_STEPS.TERMS));
        }
        dispatch(addCardDetails(cardCredentials));
        setErrorMessage('');
        if (setIsModalOpen) setIsModalOpen(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else if (error?.response && error?.response?.status === 400) {
          setErrorMessage(
            error?.response?.data?.message?.message || 'Card with status already exists',
          );
          if (setIsModalOpen) setIsModalOpen(true);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message ||
              'Unable to process payment. Please enter valid card.',
          );
          if (setErrorDescription)
            setErrorDescription(error?.response?.data?.message?.subMessage || '');
          if (setIsModalOpen) setIsModalOpen(true);
        }
      });
  };
};

export const deletePaymentMethodData = (
  id: string,
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  isAdmin: boolean,
  customerId?: number,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);
    const url = constructApiUrl(`v1/payment-methods/${id}`, isAdmin, customerId);
    api
      .delete(url, getConfig(token))
      .then(() => {
        dispatch(removeCard(id));
        setErrorMessage('');
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message || 'An error occurred while removing the card.',
          );
        }
      });
  };
};

export const updatePaymentMethodData = (
  id: string,
  updatedStatus: string,
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  isAdmin: boolean,
  customerId?: number,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    const body = {
      status: updatedStatus,
    };
    setIsLoading(true);
    const url = constructApiUrl(`v1/payment-methods/${id}`, isAdmin, customerId);
    api
      .put(url, body, getConfig(token))
      .then(() => {
        dispatch(updateCard({ id, status: updatedStatus }));

        dispatch(
          fetchPaymentMethodData(setIsLoading, setErrorMessage, navigate, isAdmin, customerId),
        );

        setErrorMessage('');
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message ||
              `An error occurred while updating card status to ${updatedStatus}.`,
          );
        }
      });
  };
};

export const getCustomerPackageDetailsAsync = (
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  isAdmin: boolean,
  customerId?: number,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    setIsLoading(true);
    const url = constructApiUrl('v1/customers/support-package', isAdmin, customerId);
    const state = getState();
    const token = state.signup.userToken;

    api
      .get(url, getConfig(token))
      .then((result: { data: SubscriptionProps }) => {
        setIsLoading(false);
        dispatch(fetchSubscriptionDetails(result?.data));
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message || 'Unable to fetch customer details.',
          );
        }
      });
  };
};

export const getDashboardStatsAsync = (
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  isAdmin: boolean,
  customerId?: number,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);
    const url = constructApiUrl('v1/invoices/summary', isAdmin, customerId);
    api
      .get(url, getConfig(token))
      .then((result: { data: DashboardProps }) => {
        setIsLoading(false);
        dispatch(fetchDashboardStats(result?.data));
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message || 'Unable to fetch customers data.',
          );
        }
      });
  };
};

export const fetchInvoicesAsync = (
  page: number,
  limit: number,
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  isAdmin: boolean,
  customerId?: number,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    setIsLoading(true);
    const url = constructApiUrl(
      `v1/invoices?limit=${limit}&page=${page}`,
      isAdmin,
      customerId,
      true,
    );

    const state = getState();
    const token = state.signup.userToken;

    api
      .get(url, getConfig(token))
      .then((result: { data: InvoicesState }) => {
        setIsLoading(false);
        dispatch(fetchInvoices(result?.data));
      })
      .catch((error) => {
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message || 'Unable to fetch customers invoices.',
          );
          setIsLoading(false);
        }
      });
  };
};

export const fetchUsersAsync = (
  page: number,
  limit: number,
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  isAdmin: boolean,
  customerId?: number,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);
    const url = constructApiUrl(
      `v1/customers/users?limit=${limit}&page=${page}`,
      isAdmin,
      customerId,
      true,
    );
    api
      .get(url, getConfig(token))
      .then((result: { data: UsersState }) => {
        setIsLoading(false);
        dispatch(fetchUsers(result.data));
      })
      .catch((error) => {
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(error?.response?.data?.message?.message || 'Unable to fetch users.');
          setIsLoading(false);
        }
      });
  };
};

export const fetchCustomersAsync = (
  page: number,
  limit: number,
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  sortOrder: string,
  sortField: string,
  searchString: string,
  filterValue: string,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);
    let queryParams = `limit=${limit}&page=${page}`;

    if (sortOrder) {
      queryParams += `&sortOrder=${sortOrder}`;
    }
    if (sortField) {
      queryParams += `&sortField=${sortField}`;
    }
    if (searchString && searchString.trim().length > 0) {
      queryParams += `&searchString=${encodeURIComponent(searchString)}`;
    }
    if (filterValue) {
      queryParams += `&status=${filterValue}`;
    }
    api
      .get(`v1/customers?${queryParams}`, getConfig(token))
      .then((result: { data: CustomersState }) => {
        setIsLoading(false);
        dispatch(fetchCustomers(result.data));
      })
      .catch((error) => {
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        } else {
          setErrorMessage(
            error?.response?.data?.message?.message || 'Unable to fetch all customers.',
          );
          setIsLoading(false);
        }
      });
  };
};

export const changeSupportPackageAsync = (
  supportPackage: string,
  setIsLoading: SetIsLoadingType,
  setIsPackageModalOpen: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  isAdmin: boolean,
  customerId?: number,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);
    const url = constructApiUrl('v1/customers/support-package', isAdmin, customerId);
    api
      .put(url, { package: supportPackage }, getConfig(token))
      .then(() => {
        setIsLoading(false);
        setIsPackageModalOpen(false);
        dispatch(
          fetchCurrentUserAsync(setIsLoading, setErrorMessage, navigate, isAdmin, customerId),
        );
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        } else {
          setErrorMessage(
            error?.response?.data?.message?.message ||
              'An error occurred while changing support package.',
          );
        }
      });
  };
};

export const fetchCurrentUserAsync = (
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  isAdmin: boolean,
  customerId?: number,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    setIsLoading(true);
    const url = constructApiUrl('v1/customers/current', isAdmin, customerId);
    const state = getState();
    const token = state.signup.userToken;

    api
      .get(url, getConfig(token))
      .then((result: CustomerResult) => {
        const step = result?.data?.customer?.step;
        setIsLoading(false);
        dispatch(fetchCustomerDetails(result.data));

        if (step !== TrackStep.COMPLETED) dispatch(updateNafathStatus(result.data.nafathEnabled));
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message ||
              'An error occurred while fetching the current user.',
          );
        }
      });
  };
};

export const changeBillingGroupAsync = (
  billingGroup: string,
  setIsLoading: SetIsLoadingType,
  setIsModalOpen: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  isAdmin: boolean,
  customerId?: number,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);
    const url = constructApiUrl('v1/customers/billing-group', isAdmin, customerId);
    api
      .put(url, { gcpSecurityGroup: billingGroup }, getConfig(token))
      .then(() => {
        setIsLoading(false);
        setIsModalOpen(false);
        dispatch(
          fetchCurrentUserAsync(setIsLoading, setErrorMessage, navigate, isAdmin, customerId),
        );
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        } else {
          setErrorMessage(
            error?.response?.data?.message?.message ||
              'An error occurred while updating the billing group.',
          );
        }
      });
  };
};

export const cancelSubscriptionAsync = (
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  isAdmin: boolean,
  customerId?: number,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;

    setIsLoading(true);
    const url = constructApiUrl('v1/customers/support-package/cancel', isAdmin, customerId);
    api
      .delete(url, getConfig(token))
      .then(() => {
        setIsLoading(false);
        dispatch(getCustomerPackageDetailsAsync(setIsLoading, setErrorMessage, navigate, false));
        toast.success('Your subscription has been cancelled');
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message ||
              'An error occurred while cancelling the subscription.',
          );
        }
      });
  };
};

export const resubscribeAsync = (
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  isAdmin: boolean,
  customerId?: number,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);
    const url = constructApiUrl('v1/customers/support-package/resubscribe', isAdmin, customerId);
    api
      .put(url, {}, getConfig(token))
      .then(() => {
        setIsLoading(false);
        dispatch(getCustomerPackageDetailsAsync(setIsLoading, setErrorMessage, navigate, false));
        toast.success('New billing account has been created');
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message ||
              'An error occurred while resubscribing the package.',
          );
        }
      });
  };
};

export const inviteUserAsync = ({
  email,
  currentPage,
  limit,
  setIsLoading,
  setErrorMessage,
  navigate,
  setIsModalOpen,
  isAdmin,
  customerId,
}: InviteUserParams) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);
    const url = constructApiUrl('v1/users/invite', isAdmin, customerId);
    api
      .post(url, { email }, getConfig(token))
      .then(() => {
        dispatch(
          fetchUsersAsync(
            currentPage,
            limit,
            setIsLoading,
            setErrorMessage,
            navigate,
            isAdmin,
            customerId,
          ),
        );
      })
      .catch((error) => {
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error.response?.data?.message?.message || 'An error occurred while adding the user.',
          );
          setIsModalOpen(true);
          setIsLoading(false);
        }
      });
  };
};

export const deleteUsersAsync = ({
  userId,
  currentPage,
  limit,
  setIsLoading,
  setErrorMessage,
  navigate,
  isAdmin,
  customerId,
}: DeleteUsersParams) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);
    const url = constructApiUrl(`v1/users/${userId}`, isAdmin, customerId);
    api
      .delete(url, getConfig(token))
      .then(() => {
        dispatch(
          fetchUsersAsync(
            currentPage,
            limit,
            setIsLoading,
            setErrorMessage,
            navigate,
            isAdmin,
            customerId,
          ),
        );
      })
      .catch((error) => {
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message || 'An error occurred while deleting the user.',
          );
        }
      });
  };
};

export const retryPaymentAsync = (
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  isAdmin: boolean,
  setPaymentSuccessModal: SetIsLoadingType,
  setPaymentFailModal: SetIsLoadingType,
  customerId?: number,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);
    const url = constructApiUrl('v1/invoices/retry-payment', isAdmin, customerId);
    api
      .post(url, {}, getConfig(token))
      .then(() => {
        dispatch(
          fetchCurrentUserAsync(setIsLoading, setErrorMessage, navigate, isAdmin, customerId),
        );
        setPaymentSuccessModal(true);
        setPaymentFailModal(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setPaymentSuccessModal(false);
        setPaymentFailModal(true);
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        } else {
          setErrorMessage(
            error?.response?.data?.message?.message || 'An error occurred while retrying payment.',
          );
        }
      });
  };
};

export const fetchSignedInvoiceUrlAsync = (
  fileName: string | number,
  setInvoiceUrl: (url: string) => void,
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  isAdmin: boolean,
  customerId?: number,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    const body = {
      fileName: fileName,
    };
    const url = constructApiUrl('v1/invoices/signed-url', isAdmin, customerId);
    setIsLoading(true);
    api
      .post(url, body, getConfig(token))
      .then((result: { data: { url: string } }) => {
        setInvoiceUrl(result?.data?.url);
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        } else {
          setErrorMessage(
            error?.response?.data?.message?.message || 'The API failed while fetching invoice url',
          );
        }
      });
  };
};

export const migrateUserAsync = (
  oldGcpSubBillingAccount: string,
  setIsLoading: SetIsLoadingType,
  setIsModalOpen: SetIsLoadingType,
  setIsSuccessModalOpen: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
  customerId?: number,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);

    api
      .put(
        'v1/customers/migrate/billing-account',
        { oldGcpSubBillingAccount: oldGcpSubBillingAccount },
        getConfig(token),
      )
      .then(() => {
        setIsLoading(false);
        setIsModalOpen(false);
        setIsSuccessModalOpen(true);
        dispatch(fetchCurrentUserAsync(setIsLoading, setErrorMessage, navigate, false, customerId));
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        } else {
          setErrorMessage(
            error?.response?.data?.message?.message || 'An error occurred while migrating user.',
          );
        }
      });
  };
};

export const sendSupportEmailAsync = (
  formData: SupportFormData,
  setIsLoading: SetIsLoadingType,
  setModal: SetIsLoadingType,
  setSuccessModal: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);
    let payload: FormData | SupportFormData;
    if (formData.file) {
      payload = new FormData();
      payload.append('subject', formData.subject);
      payload.append('email', formData.email);
      payload.append('message', formData.message);
      payload.append('name', formData.name);
      payload.append('file', formData.file);
    } else {
      payload = {
        subject: formData.subject,
        email: formData.email,
        message: formData.message,
        name: formData.name,
        file: null,
      };
    }
    api
      .post('v1/email', payload, getConfig(token))
      .then(() => {
        setIsLoading(false);
        setModal(false);
        setSuccessModal(true);
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          // If a 401 error is received, handle the logout
          handleLogoutAndReset(dispatch, navigate);
        } else {
          // Handle other errors
          setErrorMessage(
            error?.response?.data?.message?.message ||
              'Unable to send support email. Please try again',
          );
        }
      });
  };
};

export const updateDesktopRecommendedPopupAsync = (
  checkedState: boolean,
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    const body = {
      desktopVersion: !checkedState,
    };
    setIsLoading(true);

    api
      .put('v1/customers/desktop/version', body, getConfig(token))
      .then(() => {
        dispatch(fetchCurrentUserAsync(setIsLoading, setErrorMessage, navigate, false));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
};

export const createNafathRequestAsync = (
  nationalId: string,
  setTransId: SetState<string>,
  setRandom: SetState<string>,
  setVerificationSeconds: SetState<number>,
  setNafathStatus: (status: NafathRequestStatus) => void,
  navigate: ReturnType<typeof useNavigate>,
  setErrorMessage: SetErrorMessageType,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    const body = {
      nationalId,
    };

    api
      .post('v1/nafath-verifications/create', body, getConfig(token))
      .then(({ data: result }: NafathApiResponse | IqamaApiResponse) => {
        // if Nafath service is disabled, verifies from server only
        if ('customer' in result) {
          setNafathStatus(NafathRequestStatus.COMPLETED);
        } else {
          // Nafath sends status in Uppercase, conversion was needed here
          const nafathStatus =
            NafathRequestStatus[result.status.toUpperCase() as keyof typeof NafathRequestStatus];
          setNafathStatus(nafathStatus);
          setTransId(result.transId);
          setRandom(result.random.toString());

          // set countdown until new request if status is cancelled
          if (nafathStatus === NafathRequestStatus.CANCELLED)
            setVerificationSeconds(
              NAFATH_VERIFICATION_SECONDS - getTotalSecondsFromCreatedAt(result.createdAt),
            );
        }
      })
      .catch((error) => {
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        }
        setNafathStatus(NafathRequestStatus.ERROR);
        setErrorMessage(
          error?.response?.data?.message?.message || 'Verification failed. Please try again!',
        );
      });
  };
};

export const getNafathRequestStatus = (
  nationalId: string,
  random: string,
  transId: string,
  setNafathStatus: (status: NafathRequestStatus) => void,
  setNameFields: (data: NafathResponseBody) => void,
  navigate: ReturnType<typeof useNavigate>,
  setErrorMessage: SetErrorMessageType,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    const body = {
      nationalId,
      random,
      transId,
    };

    api
      .post('v1/nafath-verifications/status', body, getConfig(token))
      .then(({ data: result }: NafathStatusApiResponse) => {
        // Nafath sends status in Uppercase, conversion was needed here
        const nafathStatus =
          NafathRequestStatus[result.status.toUpperCase() as keyof typeof NafathRequestStatus];

        // Handle Nafath expired identification numbers
        if (nafathStatus === NafathRequestStatus.COMPLETED && result.message) {
          setNafathStatus(NafathRequestStatus.ERROR);
          setErrorMessage(result.message);
          return;
        }

        // FE check: expire the request if the status is waiting after total verification seconds i.e. 180
        if (nafathStatus === NafathRequestStatus.WAITING)
          setNafathStatus(NafathRequestStatus.EXPIRED);
        else setNafathStatus(nafathStatus);

        // if status is completed, use Nafath response to populate name fields
        if (nafathStatus === NafathRequestStatus.COMPLETED) {
          setNameFields(result);
        }
      })
      .catch((error) => {
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        }
        setNafathStatus(NafathRequestStatus.ERROR);
        setErrorMessage(
          error?.response?.data?.message?.message || 'Verification failed. Please try again!',
        );
      });
  };
};

export const cancelNafathRequest = (
  nationalId: string,
  transId: string,
  setNafathStatus: (status: NafathRequestStatus) => void,
  navigate: ReturnType<typeof useNavigate>,
  setErrorMessage: SetErrorMessageType,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    const body = {
      nationalId,
    };

    api
      .post(`v1/nafath-verifications/cancel/${transId}`, body, getConfig(token))
      .then(() => {
        setNafathStatus(NafathRequestStatus.PENDING);
      })
      .catch((error) => {
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        }
        setNafathStatus(NafathRequestStatus.ERROR);
        setErrorMessage(
          error?.response?.data?.message?.message || 'Nafath cancellation request failed.',
        );
      });
  };
};

export const updatePaymentStepAsync = (
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);

    api
      .post('v1/customers/payment-methods', {}, getConfig(token))
      .then((result: CustomerResult) => {
        setIsLoading(false);
        dispatch(fetchCustomerDetails(result.data));
        dispatch(updateOnboardingStep(ONBOARDING_STEPS.TERMS));
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        } else {
          setErrorMessage(
            error?.response?.data?.message?.message ||
              'Unable to update the payment method. Please try again!',
          );
        }
      });
  };
};

export const updateBillingPermissionAsync = (
  customerId: number,
  isAdmin: boolean,
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);

    api
      .post(`v1/customers/assign/permission/${customerId}`, {}, getConfig(token))
      .then(() => {
        setIsLoading(false);
        dispatch(
          fetchCurrentUserAsync(setIsLoading, setErrorMessage, navigate, isAdmin, customerId),
        );
        toast.success(
          "‘Billing Account Admin’ and 'Billing Account User' permissions are successfully granted to the account.",
        );
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        } else {
          toast.warn(
            "Failed to grant ‘Billing Account Admin’ and 'Billing Account User' permissions to the account.",
          );
          setErrorMessage(
            error?.response?.data?.message?.message ||
              'Unable to update billing permission. Please try again!',
          );
        }
      });
  };
};

export const fetchWebURLsAsync = (
  setIsLoading: SetIsLoadingType,
  setErrorMessage: SetErrorMessageType,
  navigate: ReturnType<typeof useNavigate>,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const token = state.signup.userToken;
    setIsLoading(true);

    api
      .get('v1/configs/terms-and-conditions', getConfig(token))
      .then((result: ConfigURLsAPIResponse) => {
        setIsLoading(false);
        dispatch(fetchURLs(result.data));
      })
      .catch((error) => {
        setIsLoading(false);
        if (isUnauthorizedError(error)) {
          handleLogoutAndReset(dispatch, navigate);
        } else {
          setErrorMessage(error?.response?.data?.message?.message || 'Unable to fetch config URLs');
        }
      });
  };
};
