import { useEffect, useMemo, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import DropdownIcon from '../../../Assets/Images/svg/dropdownIcon.svg';
import GreenTickIcon from '../../../Assets/Images/svg/greenTick.svg';
import LogoSection from '../../../Components/Common/LogoSection';
import Spinner from '../../../Components/Common/Spinner';
import Stepper from '../../../Components/Common/Stepper';
import StepperMobile from '../../../Components/Common/StepperMobile';
import { AppDispatch, RootState } from '../../../Store';
import { ContactFieldErrors, NafathResponseBody } from '../../../Types';
import {
  cancelNafathRequest,
  createNafathRequestAsync,
  fetchBusinessInformationAsync,
  fetchCurrentUserAsync,
  getNafathRequestStatus,
} from '../../../Utils/api';
import {
  ACCOUNT_NAME_REGEX,
  INDIVIDUAL,
  NafathRequestStatus,
  NAFATH_VERIFICATION_SECONDS,
  NAME_REGEX,
  SaudiCities,
  SaudiCityProvinces,
  SaudiProvinces,
  textFieldClass,
  TrackStep,
} from '../../../Utils/constants';
import { convertArabicNumerals, splitAccountName } from '../../../Utils/helperFunctions';
import ButtonsSection from '../Common/ButtonsSection';
import SelectField from '../Common/SelectField';
import NafathError from './Modals/NafathError';
import NafathVerification from './Modals/NafathVerification';
import { socketConnection } from './socket';

const ContactInformation = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const userDetails =
    useSelector((state: RootState) => state.signup.customer?.customer?.user) ?? {};
  const customerDetails = useSelector((state: RootState) => state.signup.customer?.customer) ?? {};
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [iqamaErrorMessage, setIqamaErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    identificationNumber: customerDetails?.identificationNumber ?? '',
    firstName: customerDetails.step === TrackStep.INFO ? '' : userDetails?.firstName,
    lastName: customerDetails.step === TrackStep.INFO ? '' : userDetails?.lastName,
    arabicFirstName: userDetails?.arabicFirstName,
    arabicLastName: userDetails?.arabicLastName,
    arabicAddress: customerDetails?.arabicAddress,
    accountName: customerDetails?.accountName ?? 'cntxt-online-ind-nationalidoriqama-',
    contactEmail: customerDetails?.user?.email,
    phone: userDetails?.phone,
    address: customerDetails?.address,
    city: customerDetails?.city ?? '',
    postalCode: customerDetails?.postalCode,
    province: customerDetails?.province ?? '',
  });

  const [fieldErrors, setFieldErrors] = useState<ContactFieldErrors>({
    identificationNumber: false,
    firstName: false,
    lastName: false,
    arabicFirstName: false,
    arabicLastName: false,
    arabicAddress: false,
    accountName: false,
    phone: false,
    address: false,
    city: false,
    postalCode: false,
    province: false,
    contactEmail: false,
  });
  const [isError, setIsError] = useState(false);

  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [random, setRandom] = useState('');
  const [transId, setTransId] = useState('');
  const { userToken, verificationEnabled } = useSelector((state: RootState) => state.signup);
  // if Nafath is enabled,
  //    then fetch current verification status from identificationNumber and identificationNumberVerified
  // else if Nafath is disabled,
  //    then fetch current verification status from identificationNumber
  const verificationFlag = verificationEnabled
    ? customerDetails?.identificationNumber && customerDetails?.identificationNumberVerified
    : !!customerDetails?.identificationNumber;
  const [iqamaStatus, setIqamaStatus] = useState<NafathRequestStatus>(
    verificationFlag ? NafathRequestStatus.COMPLETED : NafathRequestStatus.PENDING,
  );
  const [verificationSeconds, setVerificationSeconds] = useState(NAFATH_VERIFICATION_SECONDS);
  const socket = useMemo(() => socketConnection(userToken), []);
  const [isNameDisabled, setIsNameDisabled] = useState(
    verificationEnabled || customerDetails?.identificationNumberVerified,
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === 'city') {
      const selectedProvince =
        SaudiCityProvinces.find((city) => city.city === value)?.province ?? '';
      setFormData((prevState) => ({
        ...prevState,
        city: value,
        province: selectedProvince,
      }));
    } else if (name === 'province') {
      setFormData((prevState) => ({
        ...prevState,
        city: '',
        [name]: value,
      }));
    } else if (name === 'lastName') {
      const sanitizedValue = value.replace(NAME_REGEX, '');
      setFormData((prevState) => ({
        ...prevState,
        lastName: sanitizedValue,
      }));
    } else {
      const sanitizedValue = value.replace(/[\u0600-\u06FF]/g, '');
      setFormData((prevState) => ({
        ...prevState,
        [name]: sanitizedValue,
      }));
    }
  };

  const renderCrVerificationStatus = () => {
    switch (iqamaStatus) {
      case NafathRequestStatus.PENDING:
      case NafathRequestStatus.EXPIRED:
      case NafathRequestStatus.REJECTED:
      case NafathRequestStatus.ERROR:
      case NafathRequestStatus.CANCELLED:
        return 'Verify';
      case NafathRequestStatus.WAITING:
      case NafathRequestStatus.STATUS_WATING:
        return <Spinner adjustContainerHeight />;
      case NafathRequestStatus.COMPLETED:
        return <img className="w-7 h-7" src={GreenTickIcon} alt="Verified" />;
      default:
        break;
    }
  };

  const verifyIqamaNumber = () => {
    const iqamaNumber = formData.identificationNumber ?? '';
    // if reverify is clicked on error modal, close error modal
    if (isErrorModalOpen) setIsErrorModalOpen(false);

    if (iqamaNumber) {
      // if user enters already verified identification number
      if (verificationFlag && iqamaNumber === customerDetails?.identificationNumber) {
        updateIqamaStatus(NafathRequestStatus.COMPLETED);
        setFieldErrors((prev) => ({
          ...prev,
          identificationNumber: false,
        }));
      } else {
        setIqamaStatus(NafathRequestStatus.WAITING);
        dispatch(
          createNafathRequestAsync(
            iqamaNumber,
            setTransId,
            setRandom,
            setVerificationSeconds,
            updateIqamaStatus,
            navigate,
            setIqamaErrorMessage,
          ),
        );
      }
    }
  };

  const updateIqamaStatus = (status: NafathRequestStatus) => {
    switch (status) {
      case NafathRequestStatus.PENDING:
        setIqamaStatus(status);
        setVerificationSeconds(NAFATH_VERIFICATION_SECONDS);
        break;
      case NafathRequestStatus.WAITING:
        setIqamaStatus(status);
        setIsVerifyModalOpen(true);
        setVerificationSeconds(NAFATH_VERIFICATION_SECONDS);
        break;
      case NafathRequestStatus.COMPLETED:
        setIqamaStatus(status);
        setIsVerifyModalOpen(false);
        setVerificationSeconds(NAFATH_VERIFICATION_SECONDS);
        setTransId('');
        setFieldErrors((prev) => ({
          ...prev,
          identificationNumber: false,
        }));
        toast.success('National ID / Iqama successfully verified', { toastId: transId });
        break;
      case NafathRequestStatus.REJECTED:
      case NafathRequestStatus.EXPIRED:
        setIqamaStatus(status);
        setIsVerifyModalOpen(false);
        setIsErrorModalOpen(true);
        setVerificationSeconds(NAFATH_VERIFICATION_SECONDS);
        setTransId('');
        setIqamaErrorMessage('Please check your National ID / Iqama and retry!');
        break;
      case NafathRequestStatus.ERROR:
        setIqamaStatus(status);
        setIsVerifyModalOpen(false);
        setVerificationSeconds(NAFATH_VERIFICATION_SECONDS);
        setTransId('');
        break;
      case NafathRequestStatus.CANCELLED:
        setIqamaStatus(status);
        setIqamaErrorMessage(
          `Please wait for ${verificationSeconds} ${
            verificationSeconds > 1 ? 'seconds' : 'second'
          } before re-attempting verification`,
        );
        break;
      case NafathRequestStatus.STATUS_WATING:
        setIqamaStatus(status);
        setIsVerifyModalOpen(false);
        break;
      default:
        break;
    }
  };

  const onVerificationTimerComplete = () => {
    const iqamaNumber = formData.identificationNumber ?? '';
    if (iqamaNumber) {
      setIqamaStatus(NafathRequestStatus.STATUS_WATING);
      dispatch(
        getNafathRequestStatus(
          iqamaNumber,
          random,
          transId,
          updateIqamaStatus,
          setNameFields,
          navigate,
          setIqamaErrorMessage,
        ),
      );
    }
  };

  const cancelIqamaRequest = () => {
    const iqamaNumber = formData.identificationNumber ?? '';
    if (iqamaNumber) {
      dispatch(
        cancelNafathRequest(iqamaNumber, transId, updateIqamaStatus, navigate, setErrorMessage),
      );
    }
  };

  const handleArabicFieldsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    const sanitizedValue = value.replace(/[^\u0600-\u06FF0-9\s.-]/g, '');

    setFormData((prevState) => ({
      ...prevState,
      [name]: sanitizedValue,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors: ContactFieldErrors = {
      identificationNumber:
        !formData.identificationNumber || iqamaStatus !== NafathRequestStatus.COMPLETED,
      firstName: !isNameDisabled && !formData.firstName,
      lastName: !isNameDisabled && !formData.lastName,
      arabicFirstName: !isNameDisabled && !formData.arabicFirstName,
      arabicLastName: !isNameDisabled && !formData.arabicLastName,
      arabicAddress: !formData.arabicAddress,
      accountName: !formData.accountName,
      phone: !formData.phone || !isValidPhoneNumber(formData.phone),
      address: !formData.address,
      city: !formData.city,
      postalCode: !formData.postalCode,
      province: !formData.province,
      contactEmail: !formData.contactEmail,
    };
    setFieldErrors(errors);

    if (iqamaStatus !== NafathRequestStatus.COMPLETED) {
      updateIqamaStatus(NafathRequestStatus.ERROR);
      setIqamaErrorMessage('Please verify National ID / Iqama');
    }

    const hasErrors = Object.values(errors).some((error) => error);
    setIsError(hasErrors);

    if (!hasErrors && formData.postalCode?.toString().length === 5) {
      dispatch(fetchBusinessInformationAsync(formData, setIsLoading, setErrorMessage, navigate));
    }
  };

  const getErrorMessage = (isError: boolean, errorMessage: string): string => {
    let finalErrorMessage = '';
    if (errorMessage) {
      finalErrorMessage = errorMessage;
    }
    if (isError) {
      finalErrorMessage = 'Please fill all required fields';
    }
    return finalErrorMessage;
  };

  const getInputClass = (fieldName: keyof ContactFieldErrors): string => {
    return `${textFieldClass} ${fieldErrors[fieldName] ? 'border-red-0' : ''}`;
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const accountNamePrefix = 'cntxt-online-ind-';
    const initialAccountName = `${accountNamePrefix}${formData.identificationNumber}-`;
    const inputValue = e.target.value;
    let firstName = inputValue.replace(NAME_REGEX, '');

    const availableLength = 60 - initialAccountName.length;
    firstName = firstName.substring(0, availableLength);
    const accountName = initialAccountName + firstName;

    setFormData((prevState) => ({
      ...prevState,
      firstName,
      accountName: accountName
        ?.toLowerCase()
        ?.replaceAll(' ', '-')
        ?.replace(ACCOUNT_NAME_REGEX, ''),
    }));
  };

  const onAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    let sanitizedValue = inputValue.replace(NAME_REGEX, '');
    const accountName = splitAccountName(formData.accountName, false);
    if (accountName?.substringBefore) {
      const maxAccountNameLength = 60 - accountName?.substringBefore.length;
      sanitizedValue = sanitizedValue.substring(0, maxAccountNameLength);
    }
    setFormData((prevState) => ({
      ...prevState,
      accountName: `${accountName?.substringBefore}${sanitizedValue}`
        ?.toLowerCase()
        ?.replaceAll(' ', '-')
        ?.replace(ACCOUNT_NAME_REGEX, ''),
    }));
  };

  const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;
    const accountNamePrefix = 'cntxt-online-ind-';
    const truncatedFirstName = formData.firstName.substring(
      0,
      60 - (accountNamePrefix.length + id.length + 1),
    );

    setFormData((prevState) => ({
      ...prevState,
      identificationNumber: id,
      accountName: `${accountNamePrefix}${id}-${truncatedFirstName}`
        ?.toLowerCase()
        ?.replaceAll(' ', '-'),
      // reset name fields when new ID is added
      ...(id.length < 10 && {
        firstName: '',
        lastName: '',
        arabicFirstName: '',
        arabicLastName: '',
      }),
    }));

    if (id.length < 10) {
      setIqamaStatus(NafathRequestStatus.PENDING); // reset Iqama status
    }
  };

  const handlePhoneChange = (number: string) => {
    setFormData((prevState) => ({
      ...prevState,
      phone: number,
    }));
  };

  const setNameFields = (data: NafathResponseBody) => {
    const accountNamePrefix = 'cntxt-online-ind-';
    const initialAccountName = `${accountNamePrefix}${formData.identificationNumber}-`;
    let firstName = data.englishFirstName ?? formData.firstName;

    const availableLength = 60 - initialAccountName.length;
    firstName = firstName.substring(0, availableLength);
    const accountName = initialAccountName + firstName;

    setFormData((prev) => {
      return {
        ...prev,
        firstName: data.englishFirstName,
        lastName: data.englishLastName,
        arabicFirstName: data.firstName,
        arabicLastName: data.familyName ?? data.lastName,
        accountName: accountName
          ?.toLowerCase()
          ?.replaceAll(' ', '-')
          ?.replace(ACCOUNT_NAME_REGEX, ''),
      };
    });
    // name fields disabled when Nafath verification is successful
    setIsNameDisabled(true);
  };

  socket.on('connect', () => {
    console.log('Connected to WebSocket server');
  });

  socket.on(`receiveUpdate-${transId}`, (data: NafathResponseBody) => {
    console.log('Nafath callback response', data);
    // Nafath sends status in Uppercase, conversion needed here
    const nafathStatus =
      NafathRequestStatus[data.status.toUpperCase() as keyof typeof NafathRequestStatus];

    // Handle Nafath expired identification numbers
    if (nafathStatus === NafathRequestStatus.COMPLETED && data.message) {
      updateIqamaStatus(NafathRequestStatus.ERROR);
      setIqamaErrorMessage(data.message);
      return;
    }

    updateIqamaStatus(nafathStatus);
    if (nafathStatus === NafathRequestStatus.COMPLETED) {
      setNameFields(data);
    }
  });

  useEffect(() => {
    const timer = setInterval(() => {
      // start timer when verification starts or cancellation countdown starts
      if (
        iqamaStatus === NafathRequestStatus.WAITING ||
        iqamaStatus === NafathRequestStatus.CANCELLED
      ) {
        setVerificationSeconds((prev) => (prev > 0 ? prev - 1 : 0));
        if (iqamaStatus === NafathRequestStatus.WAITING)
          verificationSeconds === 0 && onVerificationTimerComplete();
        else {
          setIqamaErrorMessage(
            `Please wait for ${verificationSeconds} ${
              verificationSeconds > 1 ? 'seconds' : 'second'
            } before re-attempting verification`,
          );
          verificationSeconds === 0 && setIqamaStatus(NafathRequestStatus.PENDING);
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [iqamaStatus, verificationSeconds]);

  // fetch Nafath enable status
  useEffect(() => {
    dispatch(fetchCurrentUserAsync(setIsLoading, setErrorMessage, navigate, false));
  }, []);

  // update Nafath status
  useEffect(() => {
    setIsNameDisabled(verificationEnabled);
    setIqamaStatus(verificationFlag ? NafathRequestStatus.COMPLETED : NafathRequestStatus.PENDING);
  }, [verificationEnabled]);

  const errorHeading =
    iqamaStatus === NafathRequestStatus.EXPIRED ? 'Code Expired' : 'Verification Failed';
  const errorDescription =
    iqamaStatus === NafathRequestStatus.EXPIRED
      ? 'Please make sure you select the correct code within the time range to verify your Identity'
      : 'We are unable to identify you. Please make sure you select the correct number';

  const isIqamaError =
    iqamaStatus === NafathRequestStatus.EXPIRED ||
    iqamaStatus === NafathRequestStatus.REJECTED ||
    iqamaStatus === NafathRequestStatus.ERROR ||
    iqamaStatus === NafathRequestStatus.CANCELLED;

  const DISABLED_CLASS = 'bg-gray-4 text-gray-1 text-opacity-70';

  return (
    <div className="w-full h-full flex items-stretch lg:items-center justify-center">
      {isLoading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="border-[1px] border-gray-3 rounded-lg py-4 px-8 bg-white-0 min-h-[680px] w-[90vw] lg:w-[1400px] overflow-auto flex flex-col justify-between poppins-500">
            <LogoSection />
            <Stepper type={INDIVIDUAL} currentStep={1} />
            <StepperMobile
              stepNumber={1}
              stepName="Contact Information"
              nextStep="Support Package"
              totalSteps={3}
            />
            <div className="flex flex-col md:flex-row w-full gap-12 mb-4">
              <div className="w-[90%]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm text-gray-1 leading-loose">
                    National ID / Iqama
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <div className="relative flex">
                  <input
                    className={`pr-14 py-4 [&::-webkit-outer-spin-button]:hidden [&::-webkit-inner-spin-button]:hidden ${
                      isIqamaError ? 'border-red-0' : 'border-gray-2'
                    } ${getInputClass(
                      'identificationNumber',
                    )} [&::-webkit-outer-spin-button]:hidden [&::-webkit-inner-spin-button]:hidden`}
                    type="text"
                    placeholder="National ID / Iqama"
                    onChange={handleIdChange}
                    name="identificationNumber"
                    style={{ MozAppearance: 'textfield' }}
                    value={formData.identificationNumber ?? ''}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.slice(0, 10);
                      e.target.value = convertArabicNumerals(e.target.value);
                    }}
                  />
                  <button
                    className={`w-16 h-10 text-gray-1 pt-4 underline absolute top-0 right-0 text-[16px] underline-offset-4 font-normal ${
                      formData.identificationNumber?.length !== 10 && 'opacity-30'
                    } ${
                      formData.identificationNumber?.length === 10 && 'opacity-70 cursor-pointer'
                    }`}
                    type="button"
                    onClick={() => verifyIqamaNumber()}
                    disabled={
                      formData.identificationNumber?.length !== 10 ||
                      iqamaStatus === NafathRequestStatus.WAITING ||
                      iqamaStatus === NafathRequestStatus.COMPLETED
                    }
                  >
                    <div className="flex justify-center items-center">
                      {renderCrVerificationStatus()}
                    </div>
                  </button>

                  {formData.identificationNumber && isIqamaError && (
                    <p className="text-red-0 text-xs absolute top-[105%] right-0 left-0">
                      {iqamaErrorMessage}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-[90%]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm text-gray-1 leading-loose">First Name</h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${getInputClass('firstName')} ${
                    isNameDisabled && DISABLED_CLASS
                  }`}
                  type="text"
                  placeholder="First Name"
                  onChange={handleFirstNameChange}
                  name="firstName"
                  value={formData.firstName || ''}
                  maxLength={40}
                  disabled={isNameDisabled}
                />
              </div>
              <div className="w-[90%]" dir="rtl" lang="ar">
                <div className="flex gap-1 items-center">
                  <h3
                    style={{ fontFamily: "'Amiri', serif" }}
                    className="text-right text-sm font-medium leading-loose text-gray-1"
                  >
                    الاسم الأول
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${getInputClass('arabicFirstName')} ${
                    isNameDisabled && DISABLED_CLASS
                  }`}
                  style={{ fontFamily: "'Amiri', serif" }}
                  type="text"
                  placeholder="الاسم الأول"
                  onChange={handleArabicFieldsChange}
                  name="arabicFirstName"
                  value={formData.arabicFirstName || ''}
                  maxLength={40}
                  disabled={isNameDisabled}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-12  mb-4">
              <div className="w-[90%]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm text-gray-1 leading-loose">Account Name</h3>
                  <p className="text-red-0">*</p>
                </div>
                <div className="relative flex">
                  <div
                    className={`flex items-center relative ${getInputClass(
                      'accountName',
                    )} focus-within:border-transparent focus-within:outline-none focus-within:ring-2 focus-within:ring-[#2563eb] relative [&_input]:outline-none ${DISABLED_CLASS}`}
                  >
                    <div className="flex w-full text-left md:whitespace-nowrap overflow-auto [&::-webkit-scrollbar]:hidden">
                      <span className="w-fit pt-2 text-nowrap">
                        {splitAccountName(formData.accountName || '', false)?.substringBefore}
                      </span>
                      <input
                        className={`w-full overflow-auto py-2 ${DISABLED_CLASS}`}
                        name="accountName"
                        type="text"
                        placeholder="First Name"
                        value={splitAccountName(formData.accountName || '', false)?.substringAfter}
                        onChange={onAccountNameChange}
                        maxLength={150}
                        disabled={isNameDisabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[90%]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm text-gray-1 leading-loose">Last Name</h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${getInputClass('lastName')} ${
                    isNameDisabled && DISABLED_CLASS
                  }`}
                  type="text"
                  placeholder="Last Name"
                  onChange={handleChange}
                  name="lastName"
                  value={formData.lastName || ''}
                  maxLength={40}
                  disabled={isNameDisabled}
                />
              </div>
              <div className="w-[90%]" dir="rtl" lang="ar">
                <div className="flex gap-1 items-center">
                  <h3
                    style={{ fontFamily: "'Amiri', serif" }}
                    className="text-right justify-between text-sm font-medium leading-loose text-gray-1"
                  >
                    اسم العائلة
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${getInputClass('arabicLastName')} ${
                    isNameDisabled && DISABLED_CLASS
                  }`}
                  style={{ fontFamily: "'Amiri', serif" }}
                  type="text"
                  placeholder="اسم العائلة"
                  onChange={handleArabicFieldsChange}
                  name="arabicLastName"
                  value={formData.arabicLastName || ''}
                  maxLength={40}
                  disabled={isNameDisabled}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-12  mb-4">
              <div className="w-[90%]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    Email
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${textFieldClass} ${DISABLED_CLASS}`}
                  type="email"
                  placeholder="Email"
                  onChange={handleChange}
                  name="contactEmail"
                  maxLength={70}
                  value={formData.contactEmail}
                  disabled
                />
              </div>
              <div className="w-[90%]  mb-4 md:mb-0">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm font-medium leading-loose text-gray-1">Phone</h3>
                  <p className="text-red-0">*</p>
                </div>
                <div
                  className={`${getInputClass(
                    'phone',
                  )} focus-within:outline-none py-4 focus-within:ring-2 focus-within:border-none focus-within:ring-[#2563eb] relative [&_input]:outline-none [&>div>div>.PhoneInputCountryIcon]:h-5 [&>div>div>.PhoneInputCountryIcon]:w-8`}
                >
                  <PhoneInput
                    defaultCountry="SA"
                    name="phone"
                    placeholder="Phone number"
                    value={formData.phone ?? ''}
                    onChange={handlePhoneChange}
                    limitMaxLength
                  />
                  {formData.phone && isValidPhoneNumber(formData.phone) === false && (
                    <p className="text-red-0 text-xs absolute top-[105%] right-0 left-0">
                      Invalid Phone Number
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-8 mb-4">
              <div className="w-[90%]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    Street Address
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${getInputClass('address')}`}
                  type="text"
                  placeholder="Street Address"
                  onChange={handleChange}
                  name="address"
                  value={formData.address ?? ''}
                  maxLength={150}
                />
              </div>
              <div className="w-[90%]" dir="rtl" lang="ar">
                <div className="flex gap-1 items-center">
                  <h3
                    style={{ fontFamily: "'Amiri', serif" }}
                    className="text-right text-sm font-medium leading-loose text-gray-1"
                  >
                    عنوان الشارع
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  style={{ fontFamily: "'Amiri', serif" }}
                  className={`py-4 ${getInputClass('arabicAddress')}`}
                  type="text"
                  placeholder="عنوان الشارع"
                  onChange={handleArabicFieldsChange}
                  name="arabicAddress"
                  value={formData.arabicAddress ?? ''}
                  maxLength={120}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-8 mb-4">
              <div className="w-[90%]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    City
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <SelectField
                  className={`py-4 ${getInputClass('city')} bg-white-0`}
                  options={SaudiCities}
                  value={formData.city || ''}
                  onChange={handleChange}
                  name="city"
                  dropdownIcon={DropdownIcon}
                />
              </div>
              <div className="w-[90%]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm font-medium leading-loose text-gray-1">
                    Postal Code
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={
                    getInputClass('postalCode') +
                    ` [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none py-4 ${
                      formData.postalCode?.toString().length !== 0 &&
                      formData.postalCode?.toString().length !== 5 &&
                      formData.postalCode !== null
                        ? 'border-red-0'
                        : 'border-gray-2'
                    }`
                  }
                  type="text"
                  placeholder="Postal Code"
                  style={{ MozAppearance: 'textfield' }}
                  onChange={handleChange}
                  name="postalCode"
                  value={formData.postalCode ?? ''}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.slice(0, 5);
                    e.target.value = convertArabicNumerals(e.target.value);
                  }}
                />
                {formData.postalCode?.toString().length !== 0 &&
                  formData.postalCode?.toString().length !== 5 &&
                  formData.postalCode !== null && (
                    <p className="text-red-0 text-xs">Postal code should have 5 digits</p>
                  )}
              </div>
              <div className="w-[90%]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    Province
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <SelectField
                  className={`py-4 ${getInputClass('province')} bg-white-0`}
                  options={SaudiProvinces}
                  value={formData.province}
                  onChange={handleChange}
                  name="province"
                  dropdownIcon={DropdownIcon}
                />
              </div>
              <div className="w-[90%]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm font-medium leading-loose text-gray-1">
                    Country
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${textFieldClass} ${DISABLED_CLASS}`}
                  type="text"
                  value="Saudi Arabia"
                  disabled
                  maxLength={40}
                />
              </div>
            </div>

            <ButtonsSection
              errorMessage={getErrorMessage(isError, errorMessage)}
              isBackButton={false}
              isNextButton={true}
              isSubmitButton={true}
            />
          </div>
        </form>
      )}
      {isVerifyModalOpen && (
        <NafathVerification
          identificationNumber={formData.identificationNumber}
          setIsModalOpen={setIsVerifyModalOpen}
          totalSeconds={verificationSeconds}
          random={random}
          onCancelRequest={cancelIqamaRequest}
        />
      )}
      {isErrorModalOpen && (
        <NafathError
          identificationNumber={formData.identificationNumber}
          setIsModalOpen={setIsErrorModalOpen}
          heading={errorHeading}
          description={errorDescription}
          onReverifyClick={verifyIqamaNumber}
        />
      )}
      <ToastContainer
        className="!w-max"
        position="bottom-center"
        hideProgressBar={true}
        autoClose={8000}
        closeOnClick
        pauseOnHover
      />
    </div>
  );
};
export default ContactInformation;
