import { Dispatch, SetStateAction, useState } from 'react';
import ArrowIcon from '../../../Assets/Images/svg/arrowIcon.svg';
import CopyIcon from '../../../Assets/Images/svg/CopyIcon.svg';

type InfoAttributeProps = {
  title: string;
  value: string;
  tagline?: string;
  subValue?: string;
  buttonText?: string;
  update?: () => void;
  textColor?: string;
  isCopy?: boolean;
};
const InfoAttribute = ({
  title,
  value,
  subValue,
  tagline,
  update,
  buttonText,
  textColor,
  isCopy = false,
}: InfoAttributeProps) => {
  const [copiedValue, setCopiedValue] = useState(false);
  const [copiedSubValue, setCopiedSubValue] = useState(false);

  const handleCopy = (value: string, setCopied: Dispatch<SetStateAction<boolean>>) => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <div className="flex flex-col items-start">
      <h2 className="text-base text-gray-7 poppins-500">{title}</h2>
      <div className="flex items-center gap-3">
        <h2 className={`text-[22px] poppins-700 text-gray-1 mt-0 ${textColor}`}>{value}</h2>
        {isCopy && (
          <button onClick={() => handleCopy(value, setCopiedValue)}>
            <img src={CopyIcon} alt="Clipboard" />
          </button>
        )}
        {copiedValue && <p className="text-sm text-green-0">Copied</p>}
      </div>
      {tagline && <h2 className="text-[18px] poppins-500 text-gray-1 opacity-[70%]">{tagline}</h2>}
      {subValue && (
        <div className="flex items-center gap-3">
          <p className="text-base text-gray-1 poppins-300 opacity-[70%] mt-0">{subValue}</p>
          {isCopy && (
            <button onClick={() => handleCopy(subValue, setCopiedSubValue)}>
              <img src={CopyIcon} alt="Clipboard" />
            </button>
          )}
          {copiedSubValue && <p className="text-sm text-green-0">Copied</p>}
        </div>
      )}
      {update && (
        <button className="flex flex-row items-center" onClick={update}>
          <p className="text-base poppins-300 text-blue-1 mr-[5px]">{buttonText}</p>
          <img src={ArrowIcon} alt="Arrow" />
        </button>
      )}
    </div>
  );
};
export default InfoAttribute;
